<template>
  <RouterView />
</template>

<script>
export default {
  name: "App",
};
</script>
<style>
.v-data-table__wrapper {
  overflow-x: auto !important;
}
</style>