<template>
  <VDialog :value="value" persistent max-width="560px" scrollable>
    <VCard class="py-3">
      <VCardTitle class="ml-1 card_title--lg">
        <span v-if="isOnline">{{ $t("onlineMode") }}</span>
        <span v-else>{{ $t("offlineMode") }}</span>
      </VCardTitle>
      <VCardText class="px-4">
        <VContainer class="mt-1">
          <VAlert
            color="#005499"
            class="px-5 py-5"
            text
            type="info"
            icon="mdi-bullhorn-outline"
          >
            {{
              isOnline
                ? $t("onchangeToModeOnline")
                : $t("onchangeToModeOffline")
            }}
            <p>{{ isOnline ? $t("modeOnlineInfo") : $t("modeOfflineInfo") }}</p>
            <VImg
              v-if="!isOnline"
              :lazy-src="require('@/assets/images/mode-online.png')"
              :src="require('@/assets/images/mode-online.png')"
              max-height="68"
              max-width="250"
              class="rounded-pill"
            ></VImg>
          </VAlert>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VSpacer></VSpacer>
        <VBtn @click="cancel" plain large class="text-capitalize">{{
          $t("cancel")
        }}</VBtn>
        <VBtn
          color="primary"
          :loading="pending"
          @click="confirm"
          large
          depressed
          class="text-capitalize btn-add-company px-5"
        >
          <v-icon left dark class="ml-0 mr-1"> mdi-check </v-icon>
          {{ $t("save") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>

export default {
  name: "ConfirmOfflineDialog",
  emits: ["created"],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isOnline: {
      type: Boolean,
      default: true,
    },
    pending: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    async confirm() {
      this.$emit("confirm");
    },
    async cancel() {
      this.$emit("cancel");
    },
  },

  i18n: {
    messages: {
      fr: {
        save: "Continuer",
        cancel: "Annuler",
        onlineMode: "Mode en ligne",
        offlineMode: "Mode hors ligne",
        onchangeToModeOnline: "Vous allez passer en mode en ligne.",
        onchangeToModeOffline: "Vous allez passer en mode hors ligne.",
        modeOnlineInfo:
          "Assurez-vous d'être connecté à internet avant de quitter le mode hors ligne.",
        modeOfflineInfo:
          "Pour repasser en ligne, cliquez de nouveau sur l’interrupteur.",
      },
      en: {
        save: "Continuer",
        cancel: "Cancel",
        onlineMode: "Online mode",
        offlineMode: "Offline mode",
        onchangeToModeOnline: "You are going to switch to online mode.",
        onchangeToModeOffline: "You are going to switch to offline mode.",
        modeOnlineInfo:
          "Make sure you are connected to the internet before leaving offline mode.",
        modeOfflineInfo: "To go back online, click the switch again.",
      },
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-alert__content {
  padding-top: 4px !important;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;

  p {
    color: var(--socotec-primary-socotec-black-regular, #333);
    font-weight: 400;
  }
}
::v-deep button.v-btn.btn-add-company {
  background-color: #005499 !important;
}

::v-deep button.v-btn {
  letter-spacing: normal;
}
.card_title--lg {
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 32px !important;
}
</style>
