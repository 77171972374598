const OperationIdentityCardDefault = () =>
  import(
    /* webpackChunkName: "OperationIdentityCardDefault" */ "@/components/rapsosps/operations/operation/operation-identity-card/OperationIdentityCardDefault"
  );
const OperationIdentityCardDefaultRelation = () =>
  import(
    /* webpackChunkName: "OperationIdentityCardDefaultRelation" */ "@/components/rapsosps/operations/operation/operation-identity-card/OperationIdentityCardRelation"
  );
const OperationIdentityCardDefaultMission = () =>
  import(
    /* webpackChunkName: "OperationIdentityCardDefaultMission" */ "@/components/rapsosps/operations/operation/operation-identity-card/OperationIdentityCardMission"
  );

export default [
  {
    path: "",
    name: "OperationIdentityCardDefault",
    component: OperationIdentityCardDefault,
  },
  {
    path: "received",
    name: "OperationIdentityCardDefaultRelation",
    component: OperationIdentityCardDefaultRelation,
  },
  {
    path: "contributors",
    name: "OperationIdentityCardDefaultMission",
    component: OperationIdentityCardDefaultMission,
  },
];
