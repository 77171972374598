import Pgc from "@/models/PGC";
import {
  ReferencialNode,
  RapsoSPSNode,
} from "@socotec.io/socio-vue-components";
import { renameKeys } from "@/utils/utilsGrcpRest";
import isEmpty from "lodash/isEmpty";
const proto = require("google-protobuf/google/protobuf/struct_pb");

const PGC_LEVELS = {
  CHAPTER: 1,
  THEME: 2,
  DEVICE: 3,
  DISPOSITION: 4,
};

export const grpcPropertiesToRename = {
  conditionalTitlesList: "conditionalTitles",
  conditionalDisplayList: "conditionalDisplay",
  childrenList: "children",
  mandatoryMissionCodesList: "mandatoryMissionCodes",
  missionCodesList: "missionCodes",
  technicalDocsList: "technicalDocs",
  batchResponsibilityList: "batchResponsibility",
  companyResponsibilityList: "companyResponsibility",
  filesList: "files",
  risksList: "risks",
};

/**
 * Get level 4 nodes from company
 * @param customPath
 * @param contributor
 * @returns {Array<ReferencialNode>} (lvl 4)
 */
export const companyPgcDispositions = (contributor, customPath = "") => {
  const pgcSpsNodes = Pgc.query()
    .where((node) => {
      return contributor.batches.some(
        (batch) =>
          node.batchResponsibility.includes(batch) && node.icAndPpspsImported
      );
    })
    .get()
    .map((pgc) => pgc.spsNode);

  return ReferencialNode.query()
    .with("spsNode")
    .where(
      (node) =>
        node.referencialName === "pgc" &&
        node.path.startsWith(customPath) &&
        node.level == PGC_LEVELS.DISPOSITION &&
        pgcSpsNodes.includes(node.objectId)
    )
    .get();
};

/**
 * Get level 3 nodes from company through level 4 nodes
 * @param contributor;
 * @returns {Array<ReferencialNode>} (lvl 3)
 */
export const companyPgcDispositionParent = (contributor, customPath = "") => {
  const dispositions = companyPgcDispositions(contributor);
  const parentPaths = dispositions.map((node) => {
    return node.path.split(".", PGC_LEVELS.DEVICE).join(".");
  });
  const uniquePaths = [...new Set(parentPaths)];

  return uniquePaths
    .map((parentPath) => {
      return ReferencialNode.query()
        .where(
          (node) =>
            node.referencialName === "pgc" &&
            node.path.startsWith(customPath) &&
            node.path === parentPath
        )
        .first();
    })
    .filter((node) => node);
};

export const formatModelName = (str) => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}Node`;
};

export const metadataAsString = (metadata) => {
  let metadataCopy = { ...metadata };

  Object.keys(metadataCopy).forEach((key) => {
    if (typeof metadataCopy[key] === "object") {
      metadataCopy[key] = JSON.stringify(metadataCopy[key]);
    } else {
      metadataCopy[key] = metadataCopy[key].toString();
    }
  });

  return metadataCopy;
};

export const flatNodes = (nodes = [], referencialFetched, initArr = []) => {
  let resultArr = initArr;
  nodes.forEach((node) => {
    // Append each spsNode to each related referencialNode
    node.spsNode = RapsoSPSNode.query().where("uuid", node.objectId).first();
    node.referencialName = referencialFetched;
    node = renameKeys(grpcPropertiesToRename, node);
    resultArr.push(node);
    if (node.children?.length > 0) {
      flatNodes(node.children, referencialFetched, resultArr);
    }
  });
  return resultArr;
};

export const setConditionList = (
  referencialNode,
  conditionListName,
  request
) => {
  let arrayOfStruc = [];
  for (let key in referencialNode[conditionListName]) {
    arrayOfStruc.push(
      proto.Struct.fromJavaScript(referencialNode[conditionListName][key])
    );
  }
  const setListMethodName = `set${conditionListName
    .charAt(0)
    .toUpperCase()}${conditionListName.slice(1)}List`;
  request[setListMethodName](arrayOfStruc);
};

export const conditionalsListToObject = async (
  { dispatch },
  referencialNodeResult
) => {
  let results = await Promise.all(
    referencialNodeResult.map(async (result) => {
      let childrens = result.getChildrenList();

      if (childrens.length > 0) {
        childrens = await conditionalsListToObject({ dispatch }, childrens);
      }

      const conditionalTitlesList = await dispatch(
        "conditionalTitlestoJSON",
        result
      );
      const conditionalDisplayList = await dispatch(
        "conditionalDisplaytoJSON",
        result
      );

      let obj_formated = result.toObject();
      obj_formated.childrenList = childrens;
      obj_formated.conditionalTitlesList = conditionalTitlesList;
      obj_formated.conditionalDisplayList = conditionalDisplayList;
      return obj_formated;
    })
  );
  return results;
};

const COMPARATOR_TYPES_CALLBACK = {
  "==": (conditionalValue, valueReceived) => conditionalValue === valueReceived,
  ">=": (conditionalValue, valueReceived) => conditionalValue >= valueReceived,
  ">": (conditionalValue, valueReceived) => conditionalValue > valueReceived,
  "<=": (conditionalValue, valueReceived) => conditionalValue <= valueReceived,
  "<": (conditionalValue, valueReceived) => conditionalValue < valueReceived,
  in: (conditionalValue, arr) => {
    if (typeof arr === "string") {
      arr = arr.split(",");
    }
    return arr.some((elem) => elem === conditionalValue);
  },
  "not in": (conditionalValue, arr) => {
    if (typeof arr === "string") {
      arr = arr.split(",");
    }
    return !arr.some((elem) => elem === conditionalValue);
  },
  startswith: (conditionalValue, condition) =>
    conditionalValue.startsWith(condition),
  contains: (conditionalValue, condition) =>
    conditionalValue.includes(condition),
};

const LOGICAL_OPERATORS_CALLBACK = {
  OR: (condition1 = false, condition2 = false) => condition1 || condition2,
  AND: (condition1 = true, condition2 = true) => condition1 && condition2,
};

const handleCondition = (projectMission, obj) => {
  let conditionResult;
  if (!isEmpty(obj) && !(obj.comparator || obj.logic_operator)) {
    console.error(
      obj,
      "no comparator or no logic_operator for handle conditions, we display the node by default"
    );
    // INFO - MS - 17/02/22 - return true for display the node, user can remove them after if needed.
    return true;
  }
  if (obj.comparator) {
    conditionResult = COMPARATOR_TYPES_CALLBACK[obj.comparator](
      projectMission,
      obj.value
    );
  }
  if (obj.logic_operator) {
    let firstConditionResult, secondConditionResult;
    if (obj.first_value) {
      firstConditionResult = handleCondition(projectMission, obj.first_value);
    }
    if (obj.second_value) {
      secondConditionResult = handleCondition(projectMission, obj.second_value);
    }
    conditionResult = LOGICAL_OPERATORS_CALLBACK[obj.logic_operator](
      firstConditionResult,
      secondConditionResult
    );
  }
  return conditionResult;
};

export const applyConditionalDisplay = (projectMission, conditions) => {
  let conditionsArray = [];
  for (const conditionObj of conditions) {
    const firstConditionResult = handleCondition(
      projectMission,
      conditionObj.first_value
    );
    const secondConditionResult = handleCondition(
      projectMission,
      conditionObj.second_value
    );
    conditionsArray.push(
      LOGICAL_OPERATORS_CALLBACK[conditionObj.logic_operator](
        firstConditionResult,
        secondConditionResult
      )
    );
  }
  if (conditionsArray.length > 1) {
    return conditionsArray.some((bool) => bool);
  }
  return conditionsArray[0];
};
