import { User, Agency, AgencyGroup } from "@socotec.io/socio-vue-components";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { renameKeys } from "@/utils/utilsGrcpRest";
import { AGENCY_EXCLUDE_VALIDATION } from "@/constants";

const state = {
  userFromUserManagement: {},
};

const getters = {
  getCurrentUser: () => {
    return User.query()
      .where("isCurrentUser", true)
      .with("agencyDatas.agencyGroupDatas")
      .first();
  },
  getCurrentUserFromUserManagement: (state) => {
    return state.userFromUserManagement;
  },
  /**
   * Get user by email
   * @param email
   * @returns {User|null}
   */
  getUserByUserManagementUuid: () => (usermanagementUuid) => {
    return User.query().where("usermanagementUuid", usermanagementUuid).first();
  },
  isCurrentUserTheProjectManager: (state, getter, rootState, rootGetters) => {
    return (
      getter?.isDev ||
      (rootGetters["operation/getOperation"] &&
        rootGetters["operation/getOperation"].managerUsermanagementUuid ===
          getter?.getCurrentUser?.usermanagementUuid)
    );
  },
   isCurrentUserTheProjectSubstitute: (state, getter, rootState, rootGetters) => {
    return (
      getter?.isDev ||
      (rootGetters["operation/getOperation"] &&
        rootGetters["operation/getOperation"].substituteManagerUsermanagementUuid ===
          getter?.getCurrentUser?.usermanagementUuid)
    );
  },
  isTechnicalDirection: (state, getter) => {
    return getter?.isDev || getter?.getCurrentUser.isTechnicalDirection();
  },

  isDev: (state, getter) => {
    return (
      getter?.getCurrentUserFromUserManagement?.groupsList?.includes(
        "Developer"
      ) ||
      getter?.getCurrentUserFromUserManagement?.groupsList?.includes("SocioDev")
    );
  },
  isCurrentUserManagerOrSubstitute: (state, getter, rootState, rootGetters) => {
    const operation = rootGetters["operation/getOperation"];
    const user = getter?.getCurrentUser;
    return (
      getter?.isDev ||
      (operation &&
        user &&
        [
          operation.managerUsermanagementUuid,
          operation.substituteManagerUsermanagementUuid,
        ].includes(user.usermanagementUuid))
    );
  },
  isAgencyManager: (_, getters, __, rootGetters) => {
    const currentUser = getters?.getCurrentUser;
    const operationAgency = rootGetters["operation/getAgency"];
    if (
      currentUser?.isAgencyManager() &&
      currentUser.agencyDatas &&
      operationAgency
    ) 
      return operationAgency.code == currentUser.agencyDatas.code;
    
    return false;
  },
  isActivityDirector: (_, getters, __, rootGetters) => {
    const operationAgency = rootGetters["operation/getAgency"];
    const currentUser = getters?.getCurrentUser;
    if (
      currentUser?.isActivityDirector() &&
      currentUser?.agencyDatas &&
      operationAgency
    )
      return operationAgency.agencyGroup == currentUser.agencyDatas.agencyGroup;
    return false;
  },
  isCurrentUserCanUpdate: (_, getters, __, rootGetters) => {
    const operationAgency = rootGetters["operation/getAgency"];
    if (!operationAgency) return getters.isDev;
    let conditions;
    if (AGENCY_EXCLUDE_VALIDATION.includes(operationAgency.code)) {
      // hs: if agency is national check only roles else check roles and condition
      conditions = [
        getters.getCurrentUser.isDev(),
        getters.getCurrentUser.isAgencyManager(),
        getters.getCurrentUser.isActivityDirector(),
        getters.isCurrentUserTheProjectManager,
      ];
    } else {
      conditions = [
        getters.isDev,
        getters.isAgencyManager,
        getters.isActivityDirector,
        getters.isCurrentUserTheProjectManager,
      ];
    }
    return conditions.includes(true);
  },
  isTester: (state, getters) => {
    return getters?.isDev || getters?.getCurrentUser?.isInGroup("Tester");
  }
};

const actions = {
  async initializeCurrentUser({ dispatch, commit }) {
    // Fetch user, his agency and agency group
    const userResult = await dispatch("fetchCurrentUser");
    const user = userResult.users[0];
    try {
      await commit("CURRENT_USER_FROM_USER_MANAGEMENT", {});
      const userFromUserManagementResult = await dispatch(
        "retrieveUserFromUserManagement",
        user.usermanagementUuid
      );
      // FIXME: ARx 30/12/2022 –  Get by user management because SPS users permissions are broken
      await commit(
        "CURRENT_USER_FROM_USER_MANAGEMENT",
        userFromUserManagementResult
      );
    } catch (error) {
      console.error("Fail to retrieve user from user management:\n", error);
    }
    const agencyResult = await dispatch("fetchUserAgencyAndAgencyGroup", {
      uuid: user.usermanagementUuid,
    });
    if (!agencyResult) {
      return;
    }
    await User.update({
      where: (user) => {
        return user.isCurrentUser;
      },
      data: { agency: agencyResult.agency[0].uuid },
    });
  },

  async fetchUserAgencyAndAgencyGroup(context, { uuid }) {
    const request =
      new socioGrpcClient.user_management.entities.AgencyRetrieveUserAgencyRequest();
    request.setUuid(uuid);
    try {
      const response =
        await socioGrpcClient.user_management.entities.AgencyControllerPromiseClient.retrieveUserAgency(
          request,
          {}
        );
      const agency = response.toObject();
      if (!agency.uuid) {
        return;
      }
      if (agency.agencyGroup) {
        await AgencyGroup.insert({
          data: agency.agencyGroup,
        });
        // Only store agencyGroup relation
        agency.agencyGroup = agency.agencyGroup.uuid;
      }
      return await Agency.insert({
        data: agency,
      });
    } catch (err) {
      console.log(err);
    }
  },

  async fetchCurrentUser({ rootGetters }) {
    const request =
      new socioGrpcClient.rapsosps_back.user.UserRetrieveRequest();
    request.setUuid(rootGetters["oidc/oidcUser"].usermanagementUuid);
    try {
      const response =
        await socioGrpcClient.rapsosps_back.user.UserControllerPromiseClient.retrieveByUsermanagementUuid(
          request,
          {}
        );
      const keysMap = {
        groupsList: "groups",
      };
      const userData = renameKeys(keysMap, response.toObject());
      return await User.insert({
        data: { ...userData, isCurrentUser: true },
      });
    } catch (err) {
      console.log(err);
    }
  },

  async retrieveUserFromUserManagement(context, usermanagementUuid) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.user_management.user.UserRetrieveRequest,
      { uuid: usermanagementUuid }
    );

    const response =
      await socioGrpcClient.user_management.user.UserControllerPromiseClient.retrieve(
        request,
        {}
      );
    const userResponse = response.toObject();

    return userResponse;
  },

  async getUsersFromUuids(context, uuids) {
    const request = new socioGrpcClient.rapsosps_back.user.UserListRequest();
    const metadata = {
      filters: JSON.stringify({
        is_in_usermanagement_uuids: uuids,
      }),
    };
    const response =
      await socioGrpcClient.rapsosps_back.user.UserControllerPromiseClient.list(
        request,
        metadata
      );
    const { resultsList } = response.toObject();
    await User.insertOrUpdate({
      data: resultsList,
    });
    return resultsList;
  },

  async partialUpdateUser(context, datas) {
    const request =
      new socioGrpcClient.rapsosps_back.user.UserPartialUpdateRequest();
    request.setUuid(datas.uuid);
    request.setSignature(datas.signature);
    request.setPartialUpdateFieldsList(["signature"]);
    try {
      const response =
        await socioGrpcClient.rapsosps_back.user.UserControllerPromiseClient.partialUpdate(
          request,
          {}
        );
      return await User.update({
        data: { ...response.toObject() },
      });
    } catch (err) {
      console.log(err);
    }
  },

  async searchUser(context, text) {
    const metadata = {
      filters: JSON.stringify({ search: text }),
    };
    const request = new socioGrpcClient.user_management.user.UserListRequest();
    const response =
      await socioGrpcClient.user_management.user.UserControllerPromiseClient.list(
        request,
        metadata
      );
    const users = response.toObject().resultsList;
    return users.map((user) => new User(user));
  },

  /**
   * Fetch agency by code
   * @param _
   * @param code {string} Agency code
   * @returns {Promise<Agency>}
   */
  async fetchAgencyByCode(_, code) {
    const request =
      new socioGrpcClient.user_management.entities.AgencyRetrieveAgencyByCodeRequest();

    request.setCode(code);

    const response =
      await socioGrpcClient.user_management.entities.AgencyControllerPromiseClient.retrieveAgencyByCode(
        request,
        {}
      );
    const agency = response.toObject();

    if (agency.uuid === "") {
      throw new Error("There is no agency with this code");
    }
    if (agency.agencyGroup) {
      await AgencyGroup.insert({
        data: agency.agencyGroup,
      });
      // Only store agencyGroup relation
      agency.agencyGroup = agency.agencyGroup.uuid;
    }
    await Agency.insert({
      data: agency,
    });

    return agency;
  },
};

const mutations = {
  CURRENT_USER_FROM_USER_MANAGEMENT: (state, user) => {
    state.userFromUserManagement = user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
