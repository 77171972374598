import axios from "axios";

let restReportClient = axios.create({
  baseURL: process.env.VUE_APP_U_REPORT_BACK_URL,
  headers: {},
});

const initReportAuth = (accessToken) => {
  restReportClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export default restReportClient;
export { restReportClient, initReportAuth };
