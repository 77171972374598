import i18n from "@/setup/i18n";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import store from "@/store";
import { BatchRequest } from "@socotec.io/socio-grpc-api/services/contributors/js/contributors_pb";
import {
  Company,
  CompanyProjectRole,
  Contributor,
  ContributorBatch,
  Role,
  utils,
} from "@socotec.io/socio-vue-components";
import dayjs from "dayjs";

const messages = {
  en: {
    successSwitchProjectManager: "The coordinators were manually switched",
    failedSwitchProjectManager:
      "Un unknown error occured when swapped manually, please contact support",
  },
  fr: {
    successSwitchProjectManager:
      "Les coordonnateurs ont été intervertis manuellement",
    failedSwitchProjectManager:
      "Une erreur s'est produite lors du changement manuel de pilote, veuillez contacter le support",
  },
};

i18n.mergeLocaleMessage("fr", messages.fr);
i18n.mergeLocaleMessage("en", messages.en);

const google_protobuf_struct_pb = require("google-protobuf/google/protobuf/struct_pb.js");

const { CONTRIBUTORS_ENUMS } = utils.contributors;
const UPDATE_CONTRIBUTORS_COUNT = "UPDATE_CONTRIBUTORS_COUNT";
const ADD_CONTRIBUTORS_COUNT = "ADD_CONTRIBUTORS_COUNT";
const UPDATE_MANAGER_HISTORIC = "UPDATE_MANAGER_HISTORIC";
const UPDATE_MANAGERS = "UPDATE_MANAGERS";
const RESET_CONTRIBUTORS_STATE = "RESET_CONTRIBUTORS_STATE";
const UPDATE_COMPANY_PROJECT_COUNT = "UPDATE_COMPANY_PROJECT_COUNT";
const ADD_COMPANY_PROJECT_COUNT = "ADD_COMPANY_PROJECT_COUNT";

const state = {
  contributorsCount: 0,
  companyProjectRoleCount: 0,
  managerHistoric: {},
  manager: {},
  substituteManager: {},
};

const getters = {
  getContributorsCount: (state) => {
    return state.contributorsCount;
  },
  getCompanyProjectRoleCount: (state) => {
    return state.companyProjectRoleCount;
  },
  projectContributors: () => (operationId) => {
    return Contributor.query()
      .whereHas("companyProjectRole", (query) => {
        query.where("projectId", operationId);
      })
      .with("companyProjectRole.role")
      .with("companyProjectRole.company")
      .with("companyProjectRole.batches")
      .get()
      .map((contributor) => {
        return {
          ...contributor,
          role: contributor.companyProjectRole?.role,
          company: contributor.companyProjectRole?.company,
          batches: contributor.companyProjectRole?.batches,
          roleAlias: contributor.companyProjectRole?.roleAlias,
          workDone: contributor.companyProjectRole?.workDone,
          projectedStaffing: contributor.companyProjectRole?.projectedStaffing,
        };
      });
  },
  getCompanyByUuid: () => (uuid) => {
    return Company.find(uuid);
  },
  managerHistoric: (state) => {
    return state.managerHistoric;
  },
  manager: (state) => {
    return state.manager;
  },
  substituteManager: (state) => {
    return state.substituteManager;
  },
};

const actions = {
  /**
   * Fetch contributors
   * @param commit
   * @param metadata
   * @returns {Promise<Contributor[]>}
   */
  async fetchContributors({ commit }, metadata) {
    const request =
      new socioGrpcClient.contributors.contributors.ContributorCompanyProjectRoleLightListRequest();

    const response =
      await socioGrpcClient.contributors.contributors.ContributorControllerPromiseClient.list(
        request,
        metadata
      );
    const contributors = response.getResultsList();
    const contributorsList = contributors.map((contributor) => {
      const metadata = contributor.getMetadata().toJavaScript();
      contributor = contributor.toObject();
      contributor.metadata = metadata;
      contributor.companyProjectRole = {
        ...contributor.companyProjectRole,
        batchCompanyProjectRoleList: contributor.companyProjectRole?.batchesList,
        batchesList: contributor.companyProjectRole?.batchesList?.map(
          (batch) => batch.batchId
        ),
      };
      return contributor;
    });

    commit(UPDATE_CONTRIBUTORS_COUNT, response.getCount());
    return await Contributor.create({
      data: contributorsList,
      insert: ["role", "company", "companyProjectRole"],
    });
  },

  async listContributors(_, metadata) {
    const request =
      new socioGrpcClient.contributors.contributors.ContributorCompanyProjectRoleLightListRequest();

    const response =
      await socioGrpcClient.contributors.contributors.ContributorControllerPromiseClient.list(
        request,
        metadata
      );
    const contributorsList = response.getResultsList().map((contributor) => {
      return contributor.toObject();
    });
    return contributorsList;

  },

  /**
   * Fetch all contributors
   * @param commit
   * @param params
   */
  async fetchAllContributors({ commit }, params) {
    const request =
      new socioGrpcClient.contributors.contributors.ContributorCompanyProjectRoleLightListRequest();
    const response =
      await socioGrpcClient.contributors.contributors.ContributorControllerPromiseClient.utils.listAllObjects(
        request,
        params.metadata
      );
    if (params.updateCount || params.updateCount == null) {
      commit(UPDATE_CONTRIBUTORS_COUNT, response.length);
    }
    const result = response.map((contributor) => {
      return {
        ...contributor,
        companyProjectRole: {
          ...contributor.companyProjectRole,
          batchCompanyProjectRoleList: contributor.companyProjectRole?.batchesList,
          batchesList: contributor.companyProjectRole?.batchesList?.map(
            (batch) => batch.batchId
          ),
        }
      }
    });
    return await Contributor.create({
      data: result,
      insert: ["role", "company", "companyProjectRole"],
    });
  },
  async fetchAllCompanyWithContributors({ commit }, params) {
    const request =
      new socioGrpcClient.contributors.contributors.CompanyProjectRoleListRequest();
    const response =
      await socioGrpcClient.contributors.contributors.CompanyProjectRoleControllerPromiseClient.list(
        request,
        params.metadata
      );

    const companyProjectRoles = response.getResultsList();
    const companyProjectRoleList = companyProjectRoles.map((companyProjectRole) => {
      const contributorsList = companyProjectRole
        .getContributorsList()
        ?.map((contributor) => {
          const metadata = contributor.getMetadata().toJavaScript();
          contributor = contributor.toObject();
          contributor.metadata = metadata;
          return contributor;
        });
      companyProjectRole = companyProjectRole.toObject();
      return {
        ...companyProjectRole,
        companyId: companyProjectRole.company?.uuid,
        projectId: companyProjectRole.projectId,
        roleId: companyProjectRole.role?.uuid,
        contributors: contributorsList,
        batchCompanyProjectRoleList: companyProjectRole.batchesList,
        batchesList: companyProjectRole.batchesList.map((batch) => batch.batchId),
      };
    });
    commit(UPDATE_COMPANY_PROJECT_COUNT, response.getCount());
    return await CompanyProjectRole.create({
      data: companyProjectRoleList,
      insert: ["role", "company", "project", "contributors"],
    });
  },
  /**
   * Fetch contributors by distinct bash group by company
   * @param [commit]
   * @param metadata Headers
   * @returns {Promise<*>}
   */
  async fetchContributorsByDistinctBash({ commit }, metadata) {
    const request =
      new socioGrpcClient.contributors.contributors.ContributorCompanyProjectRoleLightListRequest();
    const response =
      await socioGrpcClient.contributors.contributors.ContributorControllerPromiseClient.listContributorByDistinctBatch(
        request,
        metadata
      );

    commit(UPDATE_CONTRIBUTORS_COUNT, response.getCount());

    return response
      .getResultsList()
      .map((contributor) => contributor.toObject());
  },

  async createContributor({ commit, rootGetters }, contributor) {
    const request =
      new socioGrpcClient.contributors.contributors.ContributorInputRequest();

    request.setUserEmail(contributor.userEmail);
    request.setUserFirstName(contributor.userFirstName);
    request.setUserLastName(contributor.userLastName);
    request.setUserPhoneNumber(contributor.userPhoneNumber);
    request.setCompanyProjectRoleId(contributor.companyProjectRoleId);
    request.setInvitationByEmail(contributor.invitationByEmail);
    request.setInvitationByUsermanagementUuid(
      rootGetters["user/getCurrentUser"].usermanagementUuid
    );
    request.setIsMainContact(contributor.isMainContact);
    request.setIsMandatoryRecipient(contributor.isMandatoryRecipient);
    request.setSynchronized(true);
    request.setMetadata(
      new google_protobuf_struct_pb.Struct.fromJavaScript(contributor.metadata)
    );
    const response =
      await socioGrpcClient.contributors.contributors.ContributorControllerPromiseClient.create(
        request,
        {}
      );
    const newContributor = response.toObject();
    newContributor.metadata = response.getMetadata().toJavaScript();

    commit(ADD_CONTRIBUTORS_COUNT, 1);

    const dataInserted = await Contributor.insert({
      data: newContributor,
    });
    return dataInserted.contributor[0].uuid;
  },

  async createCompanyProjectRole({ commit }, companyProjectRole) {
    const request =
      new socioGrpcClient.contributors.contributors.CompanyProjectRoleRequest();

    request.setCompanyId(companyProjectRole.companyId);
    request.setRoleId(companyProjectRole.roleId);
    request.setProjectId(companyProjectRole.projectId);
    request.setRoleAlias(companyProjectRole.roleAlias);
    request.setWorkDone(companyProjectRole.workDone);
    request.setProjectedStaffing(companyProjectRole.projectedStaffing);

    const response =
      await socioGrpcClient.contributors.contributors.CompanyProjectRoleControllerPromiseClient.getOrCreate(
        request,
        {}
      );

    commit(ADD_COMPANY_PROJECT_COUNT, 1);

    const dataInserted = await CompanyProjectRole.create({
      data: response.toObject(),
      insert: ["role", "company"],
    });
    return dataInserted.companyProjectRole[0]?.uuid;
  },
  async updateCompanyProjectRole(_, companyProjectRole) {
    const request =
      new socioGrpcClient.contributors.contributors.CompanyProjectRoleRequest();

    request.setUuid(companyProjectRole.uuid);
    request.setCompanyId(companyProjectRole.companyId);
    request.setRoleId(companyProjectRole.roleId);
    request.setProjectId(companyProjectRole.projectId);
    request.setRoleAlias(companyProjectRole.roleAlias);
    request.setWorkDone(companyProjectRole.workDone);
    request.setProjectedStaffing(companyProjectRole.projectedStaffing);

    const response =
      await socioGrpcClient.contributors.contributors.CompanyProjectRoleControllerPromiseClient.update(
        request,
        {}
      );
    let companyProjectRoleResponse = response.toObject();
    companyProjectRoleResponse.batchesList = companyProjectRoleResponse.batchesList.map(
      (batch) => batch.batchId
    );

    const dataInserted = await CompanyProjectRole.insert({
      data: companyProjectRoleResponse,
      insert: ["role", "company", "project", "contributors"],
    });
    return dataInserted.uuid;
  },

  async updateContributor(_, contributor) {
    const request =
      new socioGrpcClient.contributors.contributors.ContributorInputRequest();
    request.setUuid(contributor.uuid);
    request.setUserEmail(contributor.userEmail);
    request.setUserFirstName(contributor.userFirstName);
    request.setUserLastName(contributor.userLastName);
    request.setUserPhoneNumber(contributor.userPhoneNumber);
    request.setProjectId(contributor.projectId);
    request.setCompanyId(contributor.companyId);
    request.setCompanyProjectRoleId(contributor.companyProjectRoleId);
    request.setInvitationByEmail(contributor.invitationByEmail);
    request.setInvitationByUsermanagementUuid(
      contributor.invitationByUsermanagementUuid
    );
    request.setRoleId(contributor.roleId);
    request.setArrivalDate(contributor.arrivalDate);
    request.setPlannedEndDate(contributor.plannedEndDate);
    request.setIsMainContact(contributor.isMainContact);
    request.setIsMandatoryRecipient(contributor.isMandatoryRecipient);
    request.setUsermanagementUuid(contributor.usermanagementUuid);
    request.setMetadata(
      new google_protobuf_struct_pb.Struct.fromJavaScript(contributor.metadata)
    );

    const response =
      await socioGrpcClient.contributors.contributors.ContributorControllerPromiseClient.update(
        request,
        {}
      );
    const updatedContributor = response.toObject();
    updatedContributor.metadata = response.getMetadata().toJavaScript();
    const dataUpdated = await Contributor.update({
      where: contributor.uuid,
      data: updatedContributor,
    });
    return dataUpdated.uuid;
  },

  async deleteContributor({ commit }, contributor) {
    const request =
      new socioGrpcClient.contributors.contributors.ContributorDestroyRequest();
    request.setUuid(contributor.uuid);
    const metadata = {
      filters: JSON.stringify({
        check_company_project: false,
      })
    }

    await socioGrpcClient.contributors.contributors.ContributorControllerPromiseClient.destroy(
      request,
      metadata
    );

    commit(ADD_CONTRIBUTORS_COUNT, -1);

    await Contributor.delete(contributor.uuid);
  },
  async deleteCompanyProjectRole({ commit }, companyProjectRole) {
    const request =
      new socioGrpcClient.contributors.contributors.CompanyProjectRoleRequest();
    request.setUuid(companyProjectRole.uuid);

    await socioGrpcClient.contributors.contributors.CompanyProjectRoleControllerPromiseClient.destroy(
      request,
      {}
    );

    commit(ADD_COMPANY_PROJECT_COUNT, -1);

    await CompanyProjectRole.delete(companyProjectRole.uuid);
  },

  async assignBatches(context, { contributorId, batches }) {
    const batchesToCreate = batches.map((b) => {
      const batch = new BatchRequest();
      batch.setBatchId(b.uuid);
      batch.setContributorId(contributorId);
      return batch;
    });
    const request =
      new socioGrpcClient.contributors.contributors.BatchBulkCreateRequest();
    request.setBatchesList(batchesToCreate);

    await socioGrpcClient.contributors.contributors.BatchControllerPromiseClient.bulkCreate(
      request,
      {}
    );
    batches.forEach((b) => {
      ContributorBatch.insert({
        data: {
          batchId: b.uuid,
          contributorId: contributorId,
          companyProjectRoleId: contributorId,
        },
      });
    });
  },

  async assignBatchesNew(context, { companyProjectRoleId, batches }) {
    const batchesToCreate = batches.map((b) => {
      const batch = new BatchRequest();
      batch.setBatchId(b.uuid);
      batch.setCompanyProjectRoleId(companyProjectRoleId);
      batch.setIncumbentCompanyName(b.incumbentCompanyName);
      batch.setCompanyRole(b.companyRole);
      return batch;
    });
    const request =
      new socioGrpcClient.contributors.contributors.BatchBulkCreateRequest();
    request.setBatchesList(batchesToCreate);

    await socioGrpcClient.contributors.contributors.BatchControllerPromiseClient.bulkCreate(
      request,
      {}
    );
    batches.forEach((b) => {
      ContributorBatch.insert({
        data: {
          batchId: b.uuid,
          companyProjectRoleId: companyProjectRoleId,
          incumbentCompanyName: b.incumbentCompanyName,
          companyRole: b.companyRole,
        },
      });
    });
  },

  async updateBatches(context, { batches }) {
    const batchesToUpdate = batches.map((b) => {
      const batch = new BatchRequest();
      batch.setUuid(b.uuid);
      batch.setIncumbentCompanyName(b.incumbentCompanyName);
      batch.setCompanyRole(b.companyRole);
      return batch;
    });
    const request =
      new socioGrpcClient.contributors.contributors.BatchBulkUpdateRequest();
    request.setBatchesList(batchesToUpdate);

    await socioGrpcClient.contributors.contributors.BatchControllerPromiseClient.bulkUpdate(
      request,
      {}
    );
    batches.forEach((b) => {
      ContributorBatch.insert({
        data: {
          batchId: b.uuid,
          companyProjectRoleId: b.companyProjectRoleId,
          incumbentCompanyName: b.incumbentCompanyName,
          companyRole: b.companyRole,
        },
      });
    });
  },
  async updateBatch(context, { batch }) {
    const request =
      new socioGrpcClient.contributors.contributors.BatchRequest();
    request.setUuid(batch.uuid);
    request.setIncumbentCompanyName(batch.incumbentCompanyName);
    request.setCompanyRole(batch.companyRole);

    const response =
      await socioGrpcClient.contributors.contributors.BatchControllerPromiseClient.update(
        request,
        {}
      );
    await ContributorBatch.insert({
      data: response.toObject(),
    });
  },

  async unassignBatches(context, { contributorId, batches }) {
    const compositePks = batches.map((b) => [b.uuid, contributorId]);
    const contributorBatches = await ContributorBatch.query().findIn(
      compositePks
    );
    const contributorBatchIds = contributorBatches.map((b) => b.uuid);
    const request =
      new socioGrpcClient.contributors.contributors.BatchBulkDestroyRequest();
    request.setUuidsList(contributorBatchIds);
    await socioGrpcClient.contributors.contributors.BatchControllerPromiseClient.bulkDestroy(
      request,
      {}
    );
    for (const batch of contributorBatches) {
      batch.$delete();
    }
  },
  async unassignBatchesNew(context, { batches }) {
    const contributorBatchIds = batches.map((b) => b.uuid);
    const request =
      new socioGrpcClient.contributors.contributors.BatchBulkDestroyRequest();
    request.setUuidsList(contributorBatchIds);
    await socioGrpcClient.contributors.contributors.BatchControllerPromiseClient.bulkDestroy(
      request,
      {}
    );
  },

  async toggleArchiveContributor(context, uuid) {
    const request =
      new socioGrpcClient.contributors.contributors.ContributorArchiveRequest();
    request.setUuid(uuid);

    await socioGrpcClient.contributors.contributors.ContributorControllerPromiseClient.archive(
      request,
      {}
    );
    const contributor = Contributor.find(uuid);
    contributor.isArchived = !contributor.isArchived;
    contributor.$save();
  },
  async toggleArchiveCompany(context, uuid) {
    const request =
      new socioGrpcClient.contributors.contributors.CompanyProjectRoleArchiveRequest();
    request.setUuid(uuid);

    await socioGrpcClient.contributors.contributors.CompanyProjectRoleControllerPromiseClient.archive(
      request,
      {}
    );
    const companyProjectRole = CompanyProjectRole.query()
      .with("contributors")
      .find(uuid);
    companyProjectRole.isArchived = !companyProjectRole.isArchived;
    companyProjectRole.contributors.forEach((contributor) => {
      contributor.isArchived = !companyProjectRole.isArchived;
      contributor.$save();
    });
    companyProjectRole.$save();
  },
  async fetchProjectHistoric({ commit }, projectId) {
    const contributorService = socioGrpcClient.contributors.contributors;
    const metadata = {
      filters: JSON.stringify({
        projectIds: [projectId],
      }),
    };
    const request = new contributorService.ManagerLogListRequest();
    const response =
      await contributorService.ManagerLogControllerPromiseClient.list(
        request,
        metadata
      );
    const historic = {};
    response.getResultsList().forEach((logResponse) => {
      const log = logResponse.toObject();
      const logDate = dayjs(log.createdAt).format("DD/MM/YYYY");
      if (!historic[logDate]) {
        historic[logDate] = [];
      }
      historic[logDate].push(log);
    });

    commit(UPDATE_MANAGER_HISTORIC, historic);
  },
  async setProjectManagers({ commit }, projectId) {
    //HACK - B.L - 22/10/2021 - Complicated query for no other reason than vuexorm not filtering correctly:
    // const manager = Contributor.query()
    //   .where("isArchived", false)
    //   .with("role", (query) => {
    //     return query.where("name", CONTRIBUTORS_ENUMS.MANAGER_ROLE);
    //   })
    //   .first();
    // like https://vuex-orm.org/guide/data/retrieving.html#relationship-constraints does not work.

    const managerRoleUuid =
      Role.query().where("name", CONTRIBUTORS_ENUMS.MANAGER_ROLE).first()
        ?.uuid || null;
    const substituteManagerRoleUuid =
      Role.query().where("name", "SUB_COORDONNATEUR_SPS").first()?.uuid || null;
    const manager = Contributor.query()
      .where((contributor) => {
        return contributor.isArchived === false && managerRoleUuid !== null;
      })
      .whereHas("companyProjectRole", (query) =>
        query.where("projectId", projectId).where("roleId", managerRoleUuid)
      )
      .first() || { userFirstName: "", userLastName: "" };

    const substituteManager = Contributor.query()
      .where((contributor) => {
        return (
          contributor.isArchived === false && substituteManagerRoleUuid !== null
        );
      })
      .whereHas("companyProjectRole", (query) =>
        query
          .where("projectId", projectId)
          .where("roleId", substituteManagerRoleUuid)
      )
      .first() || { userFirstName: "", userLastName: "" };
    commit(UPDATE_MANAGERS, { manager, substituteManager });
  },
  async switchProjectManager({ commit, state, dispatch }, projectId) {
    const request =
      new socioGrpcClient.rapsosps_back.projects.ProjectRetrieveRequest();
    request.setUuid(projectId);
    try {
      await socioGrpcClient.rapsosps_back.projects.ProjectControllerPromiseClient.swapManagers(
        request,
        {}
      );
      await commit(UPDATE_MANAGERS, {
        manager: state.substituteManager,
        substituteManager: state.manager,
      });
      await dispatch("fetchProjectHistoric", projectId);
      await store.dispatch(
        "notifications/showSuccessNotification",
        i18n.t("successSwitchProjectManager")
      );
    } catch (err) {
      await store.dispatch(
        "notifications/showErrorNotification",
        i18n.t("failedSwitchProjectManager")
      );
    }
  },

  async updateProjectManagers({ rootGetters }, coordinatorsObject) {
    const request =
      new socioGrpcClient.rapsosps_back.projects.ProjectUpdateManagersRequest();
    const projectUuid = rootGetters["operation/getOperationUuid"];
    request.setUuid(projectUuid);
    request.setManagerEmail(coordinatorsObject.coordinator.email);
    request.setManagerSocotecCode(coordinatorsObject.coordinator.socotecCode);
    request.setManagerUsermanagementUuid(coordinatorsObject.coordinator.uuid);
    request.setSubstituteManagerEmail(coordinatorsObject.subCoordinator.email);
    request.setSubstituteManagerSocotecCode(
      coordinatorsObject.subCoordinator.socotecCode
    );
    request.setSubstituteManagerUsermanagementUuid(
      coordinatorsObject.subCoordinator.uuid
    );
    try {
      await socioGrpcClient.rapsosps_back.projects.ProjectControllerPromiseClient.updateManagers(
        request,
        {}
      );
    } catch (error) {
      console.error("Cannot update Coordinators :\n", error);
      return;
    }
  },
  async RetrieveContributorByUserAndProjectUuid(_, { usermanagementUuid, projectId }) {
    const request = new socioGrpcClient.contributors.contributors.ContributorRetrieveByUsermanagementUuidForGivenProjectRequest();
    request.setUsermanagementUuid(usermanagementUuid);
    request.setProjectId(projectId);
    const response = await socioGrpcClient.contributors.contributors.ContributorControllerPromiseClient.retrieveByUsermanagementUuidForGivenProject(request, {});
    return response.toObject();
  }
};
const mutations = {
  [UPDATE_CONTRIBUTORS_COUNT]: (state, newTotal) => {
    state.contributorsCount = newTotal;
  },
  [ADD_CONTRIBUTORS_COUNT]: (state, number) => {
    state.contributorsCount += number;
  },
  [UPDATE_COMPANY_PROJECT_COUNT]: (state, newTotal) => {
    state.companyProjectRoleCount = newTotal;
  },
  [ADD_COMPANY_PROJECT_COUNT]: (state, number) => {
    state.companyProjectRoleCount += number;
  },
  [UPDATE_MANAGER_HISTORIC]: (state, historic) => {
    state.managerHistoric = historic;
  },
  [UPDATE_MANAGERS]: (state, { manager, substituteManager }) => {
    state.manager = manager;
    state.substituteManager = substituteManager;
  },
  [RESET_CONTRIBUTORS_STATE]: (state) => {
    state.contributorsCount = 0;
    state.managerHistoric = {};
    state.manager = {};
    state.substituteManager = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export { CONTRIBUTORS_ENUMS };
