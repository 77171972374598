import { Model } from "@vuex-orm/core";
import { RapsoSPSNode } from "@socotec.io/socio-vue-components";

export default class InitialConception extends Model {
  static entity = "initialConception";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.uid(""),
      createdAt: this.attr(),
      updatedAt: this.attr(),
      cspsComment: this.attr(""),
      objectStatus: this.attr(""),
      personalizedCommentary: this.attr(""),
      additionalDetails: this.attr(""),
      projectUuid: this.attr(""),
      spsNode: this.attr(null),
      spsNodeDatas: this.belongsTo(RapsoSPSNode, "spsNode"),
    };
  }
}
