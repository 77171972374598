import { socioGrpcClient } from "@/setup/socioGrpcClient";
import Operation from "@/models/Operation.js";
import { Agency, Batch } from "@socotec.io/socio-vue-components";
import {MISSIONS} from "@/utils/consts/missions";

const state = {
  currentOperation: null,
  bimdataProjectToken: null,
};

const getters = {
  getOperation: (state) => {
    return Operation.query()
      .where("uuid", state.currentOperation?.uuid)
      .with("manager")
      .with("case")
      .first();
  },
  getAgency: (state) => {
    return Agency.query()
      .where("code", state.currentOperation?.agencyCode)
      .with("agencyGroup")
      .first();
  },
  getOperationUuid: (state) => {
    return state.currentOperation ? state.currentOperation.uuid : null;
  },
  getOperationOriginId: (state) => {
    return state.currentOperation.originId;
  },
  getProjectAccessToken: (state) => {
    return state.bimdataProjectToken ? state.bimdataProjectToken.token : null;
  },
  isCurrentOperationKDAABC: (state) => {
    return [MISSIONS.KDAA, MISSIONS.KDAB, MISSIONS.KDAC].includes(
      state.currentOperation.mission
    );
  },
  isCurrentOperationKdad: (state) => {
    return state.currentOperation?.mission === MISSIONS.KDAD;
  },
  isOperationImportedFromV1: (state) => {
    return state.currentOperation.oldProjectId ? true : false;
  }
};

const actions = {
  /**
   * Fetch project list (RapsospsBack)
   * @param [_]
   * @param metadata
   * @returns {Promise<void>}
   */
  async fetchOperations(_, metadata) {
    const request =
      new socioGrpcClient.rapsosps_back.projects.ProjectListRequest();
    const response =
      await socioGrpcClient.rapsosps_back.projects.ProjectControllerPromiseClient.list(
        request,
        metadata
      );
    const resultsList = response.getResultsList();
    const projects = resultsList.map((el) => el.toObject());

    await Operation.insert({ data: projects });
  },

  /**
   * Retrieve an operation
   * @param commit
   * @param projectId
   * @returns {Operation}
   */
  async retrieveOperation({ commit }, projectId) {
    const request =
      new socioGrpcClient.rapsosps_back.projects.ProjectRetrieveRequest();

    request.setUuid(projectId);

    const response =
      await socioGrpcClient.rapsosps_back.projects.ProjectControllerPromiseClient.retrieve(
        request,
        {}
      );
    const operation = response.toObject();

    commit("SET_CURRENT_OPERATION", operation);

    await Operation.insert({ data: operation });

    return operation;
  },

  /**
   * Performs a partial update of the fields of an operation
   * @param [_]
   * @param operation
   * @returns {Promise<void>}
   */
  async updateOperation({ commit }, operation) {
    const request = new socioGrpcClient.rapsosps_back.projects.Project();

    request.setUuid(operation.uuid);
    request.setProjectName(operation.projectName);
    request.setPostcode(operation.postcode);
    request.setCity(operation.city);
    request.setAddress(operation.address);
    request.setDescription(operation.description);
    request.setIsSpaceForPersonalProvided(operation.isSpaceForPersonalProvided);
    request.setIsSpaceForPersonalProvidedNotes(
      operation.isSpaceForPersonalProvidedNotes
    );
    request.setEstimatedDuration(operation.estimatedDuration);
    request.setWorkDescription(operation.workDescription);
    request.setOpeStartDate(operation.opeStartDate);
    request.setPhotoDocumentId(operation.photoDocumentId);
    request.setIsMissionShown(operation.isMissionShown);
    request.setOtherMissions(operation.otherMissions);
    request.setContactClientUsermanagementUuid(
      operation.contactClientUsermanagementUuid
    );
    request.setTypology(operation.typology);
    request.setComment(operation.comment);
    request.setPdpDescription(operation.pdpDescription);
    request.setWorksOver400Hours(operation.worksOver400Hours);
    request.setWorksOver90000Hours(operation.worksOver90000Hours);
    request.setDangerousWorks(operation.dangerousWorks);
    request.setDangerousWorksDescription(operation.dangerousWorksDescription);
    request.setPdpWorkhours(operation.pdpWorkhours);

    const response =
      await socioGrpcClient.rapsosps_back.projects.ProjectControllerPromiseClient.update(
        request,
        {}
      );

    const updatedOperation = response.toObject();

    await Operation.insert({ data: updatedOperation });

    commit(
      "SET_CONTACT_CLIENT_USERMANAGEMENT_UUID",
      updatedOperation.contactClientUsermanagementUuid
    );

    return updatedOperation;
  },

  async fetchBimDataAccessToken({ commit, rootGetters }) {
    try {
      const request =
        new socioGrpcClient.rapsosps_back.projects.ProjectRetrieveRequest();
      request.setUuid(rootGetters["operation/getOperationUuid"]);
      const response =
        await socioGrpcClient.rapsosps_back.projects.ProjectControllerPromiseClient.projectBimdataTokenRetrieve(
          request,
          {}
        );
      commit(
        "SET_BIMDATA_PROJECT_TOKEN",
        response.toObject().bimdataProjectToken
      );
      return response.toObject().bimdataProjectToken;
    } catch (err) {
      throw new Error(err);
    }
  },

  async searchOperation(context, { searchText, filters }) {
    const metadata = {
      filters: JSON.stringify({ search: searchText, ...filters }),
    };
    const request =
      new socioGrpcClient.rapsosps_back.projects.ProjectListRequest();

    const response =
      await socioGrpcClient.rapsosps_back.projects.ProjectControllerPromiseClient.list(
        request,
        metadata
      );
    const results = response.toObject().resultsList;
    return await Promise.all(
      results.map(async (item) => {
        return await new Operation(item);
      })
    );
  },

  async previewDataToCopy(context, settings) {
    
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.rapsosps_back.projects.ProjectCopyInput,
      {
        ...settings
      }
    );
    const response =
      await socioGrpcClient.rapsosps_back.projects.ProjectControllerPromiseClient.previewDataToCopy(
        request,
        {}
      );

    return response.toObject();
  },

  async copyDataBetweenOperations(context, settings) {
    const request =
      new socioGrpcClient.rapsosps_back.projects.ProjectCopyInput();
    request.setSourceProjectUuid(settings.sourceProjectUuid);
    request.setTargetProjectUuid(settings.targetProjectUuid);
    request.setCopyPgc(settings.copyPGC);
    request.setCopyDiuo(settings.copyDIUO);
    request.setCopyContributors(settings.copyContributors);
    request.setCopyCompanies(settings.copyCompanies);
    request.setCopyBatches(settings.copyBatches);
    request.setCopyDocuments(settings.copyDocuments);
    request.setCopyAccessesAndDevices(settings.copyAccessesAndDevices);
    request.setPgcAnalysisName(settings.PGCAnalysisName);
    request.setDiuoAnalysisName(settings.DIUOAnalysisName);

    await socioGrpcClient.rapsosps_back.projects.ProjectControllerPromiseClient.copyDataBetweenProjects(
      request,
      {}
    );
    // INFO - MS - 21/02/2022 - Batches must be overrided by the duplication
    // deleteAll here is to avoid temporary duplication until page reload because we insert batches
    Batch.deleteAll();
  },

  async archiveOrUnarchiveOperation(context, { chronorapso, isArchived }) {
    const request =
      new socioGrpcClient.rapsosps_back.projects.ProjectArchiveRequest();
    request.setChronorapso(chronorapso);
    request.setIsArchived(isArchived);
    await socioGrpcClient.rapsosps_back.projects.ProjectControllerPromiseClient.archiveOrUnarchiveProject(
      request,
      {}
    );

    await Operation.update({
      where: chronorapso,
      data: { isArchived: isArchived },
    });
  },

  async resetOperationReferencial({ rootGetters }, referencialName) {
    const request =
      new socioGrpcClient.rapsosps_back.projects.ResetReferencialDataRequest();
    request.setUuid(rootGetters["operation/getOperationUuid"]);
    request.setReferencialName(referencialName);
    await socioGrpcClient.rapsosps_back.projects.ProjectControllerPromiseClient.resetReferencialData(
      request,
      {}
    );
  },

  async updateClientManagementOperation({ commit ,state}, {
    usermanagementUuid,
    substituteManagerUsermanagementUuid
  }) {
    await commit("SET_CLIENT_MANAGEMENT_IDS", {
      usermanagementUuid,
      substituteManagerUsermanagementUuid
    });
    await Operation.insert({
      data: state.currentOperation,
    });
  },
};

const mutations = {
  SET_CURRENT_OPERATION: (state, operation) => {
    state.currentOperation = operation;
  },
  SET_CONTACT_CLIENT_USERMANAGEMENT_UUID: (state, usermanagementUuid) => {
    state.currentOperation.contactClientUsermanagementUuid = usermanagementUuid;
  },
  SET_CLIENT_MANAGEMENT_IDS: (state, {
    usermanagementUuid,
    substituteManagerUsermanagementUuid
  }) => {
    state.currentOperation.managerUsermanagementUuid = usermanagementUuid;
    state.currentOperation.substituteManagerUsermanagementUuid = substituteManagerUsermanagementUuid;
  },
  SET_BIMDATA_PROJECT_TOKEN: (state, token) => {
    state.bimdataProjectToken = token;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
