import { Model } from "@vuex-orm/core";
import { Batch, Company, RapsoSPSNode } from "@socotec.io/socio-vue-components";
export default class Pgc extends Model {
  static entity = "pgc";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.uid(""),
      createdAt: this.attr(),
      updatedAt: this.attr(),
      batchResponsibility: this.attr([]),
      companyResponsibility: this.attr([]),
      batchResponsibilityData: this.hasManyBy(
        Batch,
        "batchResponsibility",
        "uuid"
      ),
      companyResponsibilityData: this.hasManyBy(
        Company,
        "companyResponsibility",
        "uuid"
      ),
      icAndPpspsImported: this.attr(),
      displayAsTextInReport: this.attr(),
      cspsComment: this.attr(),
      files: this.attr(),
      risks: this.attr(),
      projectUuid: this.attr(""),
      spsNode: this.attr(null),
      spsNodeDatas: this.belongsTo(RapsoSPSNode, "spsNode"),
    };
  }
}
