import { Model } from "@vuex-orm/core";

export default class DiuoExtra extends Model {
  static entity = "diuoExtra";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.uid(""),
      diuoType: this.attr(),
      diuoValue: this.attr(),
      notation: this.attr(),
      files: this.attr([]),
      diuoNode: this.attr(null),
    };
  }
}
