import { Alert } from "@socotec.io/socio-vue-components";
import { socioGrpcClient } from "@/setup/socioGrpcClient";

const state = {};

const getters = {
  getAlerts:
    (state, getter, rootState, rootGetters) =>
    (displayTerminated = false) => {
      let query = Alert.query()
        .where("project", rootGetters["operation/getOperationUuid"])
        .orderBy("endDate");
      if (!displayTerminated) {
        query = query.where("isLifted", false);
      }
      return query.get();
    },
};
const actions = {
  async fetchAlerts(context, metadata) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.rapsosps_back.alert.Alert,
      {}
    );

    const response =
      await socioGrpcClient.rapsosps_back.alert.AlertControllerPromiseClient.list(
        request,
        metadata
      );
    const resultList = response.toObject().resultsList;
    await Alert.insert({
      data: resultList,
    });
  },

  async createAlert(context, alert) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.rapsosps_back.alert.Alert,
      alert,
      ["$id"]
    );
    const response =
      await socioGrpcClient.rapsosps_back.alert.AlertControllerPromiseClient.create(
        request,
        {}
      );
    await Alert.insert({ data: response.toObject() });
  },

  async updateAlert(context, alert) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.rapsosps_back.alert.Alert,
      alert
    );
    const response =
      await socioGrpcClient.rapsosps_back.alert.AlertControllerPromiseClient.update(
        request,
        {}
      );
    const updatedAlert = response.toObject();
    await Alert.update({
      where: alert.uuid,
      data: updatedAlert,
    });
    return alert.uuid;
  },
};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
