import { Model } from "@vuex-orm/core";
import { RapsoSPSNode } from "@socotec.io/socio-vue-components";
import DiuoExtra from "@/models/DIUOExtra";

export default class Diuo extends Model {
  static entity = "diuo";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.uid(""),
      v1Historic: this.attr(),
      risks: this.attr(),
      cspsComment: this.attr(),
      files: this.attr(),
      personalizedCommentary: this.attr(),
      projectUuid: this.attr(""),
      spsNode: this.attr(null),
      spsNodeDatas: this.belongsTo(RapsoSPSNode, "spsNode"),
      diuoExtra: this.hasMany(DiuoExtra, "diuoNode"),
    };
  }
}
