const CaseRegistryIssuedDocuments = () =>
  import(
    /* webpackChunkName: "CaseRegistryIssuedDocuments" */ "@/components/rapsosps/cases/case/case-registry/CaseRegistryIssuedDocuments"
  );
const CaseRegistryReceivedDocuments = () =>
  import(
    /* webpackChunkName: "CaseRegistryReceivedDocuments" */ "@/components/rapsosps/cases/case/case-registry/CaseRegistryReceivedDocuments"
  );
const CaseRegistryContributors = () =>
  import(
    /* webpackChunkName: "CaseRegistryContributors" */ "@/components/rapsosps/cases/case/case-registry/CaseRegistryContributors"
  );
const CaseRegistryIncumbentCompanies = () =>
  import(
    /* webpackChunkName: "CaseRegistryReceivedDocuments" */ "@/components/rapsosps/cases/case/case-registry/CaseRegistryIncumbentCompanies"
  );

export default [
  {
    path: "",
    name: "CaseRegistryIssuedDocuments",
    component: CaseRegistryIssuedDocuments,
  },
  {
    path: "received",
    name: "CaseRegistryReceivedDocuments",
    component: CaseRegistryReceivedDocuments,
  },
  {
    path: "contributors",
    name: "CaseRegistryContributors",
    component: CaseRegistryContributors,
  },
  {
    path: "companies",
    name: "CaseRegistryIncumbentCompanies",
    component: CaseRegistryIncumbentCompanies,
  },
];
