const OperationsList = () =>
  import(
    /* webpackChunkName: "OperationsList" */ "@/components/rapsosps/operations/OperationsList"
  );
const Operation = () =>
  import(
    /* webpackChunkName: "Operation" */ "@/components/rapsosps/operations/Operation"
  );

import OperationChildrens from "./operations/operation-childrens";
import store from "@/store";

const setCurrentOperation = async (to, from, next) => {
  if (to.params.operationId !== from.params.operationId) {
    const operation = await store.dispatch("operation/retrieveOperation", to.params.operationId);
    try {
      await store.dispatch("user/fetchAgencyByCode", operation.agencyCode);
    } catch (err) {
      console.log(err);
    }
  }
  next();
};

export default [
  {
    path: "",
    name: "operationsList",
    component: OperationsList,
  },
  {
    path: ":operationId",
    component: Operation,
    children: OperationChildrens,
    beforeEnter: setCurrentOperation,
  },
];
