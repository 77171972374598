import Vue from "vue";
import VueI18n from "vue-i18n";
import { utils } from "@socotec.io/socio-vue-components";
import { commonMessages } from "@/lang/messages/index";
import RolesMessages from "../utils/i18n/role_messages";
Vue.use(VueI18n);

const messages = {
  en: {
    $vuetify: utils.globalVuetify.enGlobalVuetify,
    ...commonMessages.en,
    ...RolesMessages.en,
  },
  fr: {
    $vuetify: utils.globalVuetify.frGlobalVuetify,
    ...commonMessages.fr,
    ...RolesMessages.fr,
  },
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: messages,
  silentFallbackWarn: true,
});
