export const SET_NETWORK_STATE = "SET_NETWORK_STATE";
export const SET_SHOW_OFFLINE_MODAL = "SET_SHOW_OFFLINE_MODAL";

const state = {
  isOnline: window.navigator.onLine,
  isShowOnlineModal: true,
};

const getters = {};

const actions = {
  setNetworkState({ commit }, isOnline) {
    commit(SET_NETWORK_STATE, isOnline);
  },
  setOnlineModal({ commit }, isShowOnlineModal) {
    commit(SET_SHOW_OFFLINE_MODAL, isShowOnlineModal);
  },
};

const mutations = {
  [SET_NETWORK_STATE]: (state, value) => {
    state.isOnline = value;
  },
  [SET_SHOW_OFFLINE_MODAL](state, value) {
    state.isShowOnlineModal = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
