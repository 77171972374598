import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./setup/i18n";
import vuetify from "./setup/vuetify";
import SocioVueComponents from "@socotec.io/socio-vue-components";
import AosComponents from "@socotec.io/socio-aos-component";
import { socioGrpcClient } from "@/setup/socioGrpcClient";

import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import VueSignaturePad from "vue-signature-pad";
import "./registerServiceWorker";
import "tiptap-vuetify/dist/main.css";
import "vuetify/dist/vuetify.min.css";
import "@socotec.io/socio-vue-components/src/assets/scss/main.scss";
import wb from "./registerServiceWorker";
import { initializeAuthHooks } from "@/setup/auth-hooks";
import SocioManifest from "../socio-manifest.json";
import PackageJson from "../package.json";
import { initializeTracingAndLogging } from "@socotec.io/socio-vue-components";


initializeTracingAndLogging(SocioManifest, PackageJson, {
  maxExportBatchSize: 10,
  router,
  useDocumentLoadInstrumentation: false,
});
store.$client = socioGrpcClient;
Vue.config.productionTip = false;
Vue.prototype.$workbox = wb;
Vue.use(SocioVueComponents);
Vue.use(VueSignaturePad);
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "mdi",
});
initializeAuthHooks();
const envConfig = {
  i18nLocale: process.env.VUE_APP_I18N_LOCALE,
  i18nFallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  mapBoxApiKey: process.env.VUE_APP_MAP_BOX
}
Vue.use(AosComponents, { store, envConfig });

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
