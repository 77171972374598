import dayjs from "dayjs";
import {ReportTemplate, utils} from "@socotec.io/socio-vue-components";

const { SPS_TEMPLATE_CODE } = utils.report;

const namingRules = {
    [SPS_TEMPLATE_CODE.PGCSPS] :
        (generationData) => {
            return namingRuleOne(generationData);
        }
    ,
    [SPS_TEMPLATE_CODE.DEEP_ANALYSIS_IU]:
        (generationData) => {
            return namingRuleOne(generationData);
        }
    ,
    [SPS_TEMPLATE_CODE.DEEP_ANALYSIS_OC]:
        (generationData) => {
            return namingRuleOne(generationData);
        }
    ,
    [SPS_TEMPLATE_CODE.DIUO_FINAL_DELIVERY]:
        (generationData) => {
            return namingRuleOne(generationData);
        }
    ,
    [SPS_TEMPLATE_CODE.COMMON_INSPECTION_REPORT]:
        (generationData) => {
            return commonInspectionReportNamingRule(generationData);
        }
    ,
    [SPS_TEMPLATE_CODE.CISSCT_CONVOCATION]:
        (generationData) => {
            return namingRuleTwo(generationData);
        }
    ,
    [SPS_TEMPLATE_CODE.COMMON_INSPECTION_CONVOCATION]:
        (generationData) => {
            return namingRuleTwo(generationData);
        }
    ,
    [SPS_TEMPLATE_CODE.COORDINATION_NOTE]:
        (generationData) => {
            return coordinationNoteNamingRule(generationData);
        }
    ,
    [SPS_TEMPLATE_CODE.JOURNAL_REGISTER]:
        (generationData) => {
            return registerJournal(generationData);
        }
    ,
}

export const REPORTS_NEEDS_VERSION_UPDATE = [
    SPS_TEMPLATE_CODE.PGCSPS,
    SPS_TEMPLATE_CODE.DEEP_ANALYSIS_IU,
    SPS_TEMPLATE_CODE.DEEP_ANALYSIS_OC,
    SPS_TEMPLATE_CODE.DIUO_FINAL_DELIVERY,
    SPS_TEMPLATE_CODE.COMMON_INSPECTION_REPORT,
    SPS_TEMPLATE_CODE.COORDINATION_NOTE
]

const namingRuleOne = ({operationName, reportTemplateName}) => {
    /**
     * Naming rule for reports: PGCSPS / Analyse IU approfondie / Analyse OC approfondie / Remise du DIUO Finale
     * **/
    const generatedName = `${operationName} - ${reportTemplateName} en date du ${dayjs().format("DD-MM-YY")}`;
    if(isNameLengthExceeded(generatedName))
       return `${getInitials(operationName, generatedName.length)} - ${reportTemplateName} en date du ${dayjs().format("DD-MM-YY")}`;
    return generatedName;
}

const namingRuleTwo = ({operationName, reportTemplateName, reportGenerationData}) => {
    /**
     * Naming rule for reports: Convocation CISSCT & Convocation Inspections-communes
     * **/
    const generatedName = `${operationName} - ${reportTemplateName + (reportGenerationData.subject?.dateTime ? " du " + dayjs(reportGenerationData.subject.dateTime).format("DD-MM-YY") : "")}`;
    if(isNameLengthExceeded(generatedName))
        return `${getInitials(operationName, generatedName.length)}} - ${reportTemplateName + (reportGenerationData.subject?.dateTime ? " du " + dayjs(reportGenerationData.subject.dateTime).format("DD-MM-YY") : "")}`;
    return generatedName;
}

const commonInspectionReportNamingRule = ({operationName, reportTemplateName, reportGenerationData}) => {
    const generatedName = `${operationName} - ${reportTemplateName}${getRepresentatives(reportGenerationData.persons.representatives, false)}`;
    if(isNameLengthExceeded(generatedName))
        return `${getInitials(operationName, generatedName.length)} - ${reportTemplateName}${getRepresentatives(reportGenerationData.persons.representatives, true)}`;
    return generatedName;
}

const coordinationNoteNamingRule = ({operationName, reportTemplateName, reportGenerationData}) => {
    const date = reportGenerationData.visitDate
      ? `du ${dayjs(reportGenerationData.visitDate).format("DD-MM-YY")}`
      : "";
    const generatedName = `${operationName} - ${
        reportTemplateName}${getDocumentNature(reportGenerationData.documentNature)} ${date}`;

    if(isNameLengthExceeded(generatedName))
        return `${getInitials(operationName)} - ${reportTemplateName}${getDocumentNature(reportGenerationData.documentNature)} ${date}`;
    return generatedName;
}

const registerJournal = ({operationName, reportGenerationData}) => {
    const generatedName = `${operationName} - ${getRJType(reportGenerationData.RJtype)} en date du ${dayjs().format("DD-MM-YY")}`;
    if(isNameLengthExceeded(generatedName))
        return `${getInitials(operationName)} - ${getRJType(reportGenerationData.RJtype)} en date du ${dayjs().format("DD-MM-YY")}`;
    return generatedName;
}

export const reportNameGenerator = (operationName, reportTemplateUuid, reportGenerationData) => {
    const reportTemplate = ReportTemplate.query()
        .where("uuid", reportTemplateUuid)
        .first();
    return namingRules[reportTemplate.code]
        ? namingRules[reportTemplate.code]({
            operationName: operationName,
            reportTemplateName:reportTemplate.name,
            reportGenerationData: reportGenerationData
        })
        : generateDefaultName(operationName, reportTemplate.name);
}

export const generateDefaultName = (operationName, reportTemplateName) => {
    const generatedName = `${operationName} - ${reportTemplateName} en date du ${dayjs().format("DD-MM-YYYY")}`;
    if(isNameLengthExceeded(generatedName))
        return `${getInitials(operationName)} - ${reportTemplateName} en date du ${dayjs().format("DD-MM-YYYY")}`;
    return generatedName;
}

const getRepresentatives = (representatives, nameLengthExceeded=false) => {
    if(representatives.length > 0){
        if(nameLengthExceeded){
            const representativesNames = representatives.map(representative => representative.company?.title);
            return " - " + representativesNames.map(representative =>
                getInitials(representative)
            ).join("-");
        }
        return " - " + representatives.map(representative => representative.company?.title).join("-");
    }
    return "";
}
const getDocumentNature = (documentNature) => {
    const DOCUMENT_NATURES = {
        "conceptionMeeting": "Réunion de conception",
        "siteMeeting": "Réunion de chantier",
        "siteVisit": "Visite du site",
        "observations": "Observations"
    }
    if(Object.keys(documentNature).length > 0){
        return " - " + DOCUMENT_NATURES[documentNature.siteProgression]
    }
    return "Observations";
}

const getRJType = (RJtype) => {
    const RJ_TYPES = {
        "JOURNAL_REGISTER": "Extrait du RJC",
        "PPSPS": "Etat des IC et PPSPS"
    }
    return RJ_TYPES[RJtype]
}

const isNameLengthExceeded = (generatedName) => {
    return generatedName.length > 256;
}

const getInitials = (longName) => {
    return longName.split(" ").map(word => word[0].toUpperCase()).join("");
}