import { Client } from "@socotec.io/socio-vue-components";
import { socioGrpcClient } from "@/setup/socioGrpcClient";

const state = {};

const getters = {};

const actions = {
  /**
   * Retrieve a client
   * @param [_]
   * @param clientId Client uuid
   * @returns {Promise<Client>}
   */
  async fetchClient(_, clientId) {
    const request =
      new socioGrpcClient.rapsosps_back.projects.ClientRetrieveRequest();

    request.setUuid(clientId);

    const response =
      await socioGrpcClient.rapsosps_back.projects.ClientControllerPromiseClient.retrieve(
        request,
        {}
      );
    const client = response.toObject();

    await Client.insert({
      data: client,
    });

    return client;
  },

  /**
   * Retrieve a atlas client
   * @param [_]
   * @param clientId Client uuid
   * @returns {Promise<Client>}
   */
  async fetchAtlasClient(_, clientId) {
    const request =
      new socioGrpcClient.atlas_as_a_service.atlas.ClientRetrieveRequest();

    request.setUuid(clientId);

    const response =
      await socioGrpcClient.atlas_as_a_service.atlas.ClientControllerPromiseClient.retrieve(
        request,
        {}
      );

    return response.toObject();
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
