<template>
  <VRow justify="center">
    <VDialog :value="dialog" persistent max-width="600">
      <VCard class="py-3">
        <VCardTitle class="ml-1 card_title--lg text-h5">
          {{ $t("dialogTitle") }}
        </VCardTitle>
        <VCardText class="px-4 dialog-content">
          <VContainer class="mt-1">
            <VAlert
              color="#005499"
              class="px-5 py-5"
              text
              type="info"
              icon="mdi-bullhorn-outline"
            >
              {{
                 $t("dialogContentTitle")
              }}
              <p>{{ $t("dialogContent") }}</p>
            </VAlert>
            <div v-if="reports.length > 0" class="offline-reports-list pa-3 mt-3">
              <div class="font-weight-bold mb-2">
                {{ $t("existingReports") }}:
              </div>
              <ul>
                <li v-for="report in reports" :key="report.uuid">
                  {{ report.title }} - Créé le
                  {{ formattedDate(report.createdAt) }}
                </li>
              </ul>
            </div>
          </VContainer>
        </VCardText>
        <VCardActions class="justify-end">
          <VBtn
            color="blackdarken-1"
            class="text-none"
            @click="backOnline"
            depressed
            outlined
          >
            <v-icon left dark> mdi-web-check </v-icon>
            {{ $t("backOnline") }}
          </VBtn>
          <VBtn
            color="primary text-none darken-1"
            @click="pushToOfflineReportGeneration"
            depressed
          >
            <v-icon left dark> mdi-check </v-icon>
            {{ $t("createReport") }}
          </VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
  </VRow>
</template>

<script>
import { utils } from "@socotec.io/socio-vue-components";

export default {
  props: {
    reports: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: true,
      selectedTemplate: utils.report.templateCodes.COMMON_INSPECTION_REPORT,
      offlineReportTemplates: [
        {
          code: utils.report.templateCodes.COMMON_INSPECTION_REPORT,
          name: this.$t(utils.report.templateCodes.COMMON_INSPECTION_REPORT),
        },
        {
          code: utils.report.templateCodes.COORDINATION_NOTE,
          name: this.$t(utils.report.templateCodes.COORDINATION_NOTE),
        }
      ]
    };
  },
  methods: {
    formattedDate(isoDate) {
      return new Date(isoDate).toLocaleDateString(navigator.language, {});
    },
    pushToOfflineReportGeneration() {
      this.dialog = false;
      this.$store.dispatch("network/setOnlineModal", false)
      this.$router.push({
              name: 'offline-report-generation',
              params: {
                templateCode: this.selectedTemplate,
                step: '1',
              },
            });
    },
    backOnline(){
      this.$emit("back-online");
    }
  },
  i18n: {
    messages: {
      fr: {
        dialogTitle: "Mode hors ligne",
        dialogContentTitle: "Vous êtes actuellement en mode hors ligne.",
        dialogContent: `Vous avez la possibilité d'initier la création d'un compte rendu d'inspection commune. Pour le valider et le générer, il est
        obligatoire de se reconnecter.`,
        createReport: "Créer le rapport",
        existingReports: "Livrables hors ligne existants",
        backOnline: "Repasser en ligne",
        selectReportLabel: "Choix du rapport",
        [utils.report.templateCodes.COORDINATION_NOTE]: "Note de coordination",
        [utils.report.templateCodes.COMMON_INSPECTION_REPORT]: "Compte rendu d'inspection commune",
      },
      en: {
        dialogTitle: "Offline mode",
        dialogContentTitle: "You are currently in offline mode.",
        dialogContent: `You have the possibility to initiate the creation of a common inspection report. To validate and generate it, it is
        mandatory to reconnect.`,
        createReport: "Create report",
        existingReports: "Existing offline reports",
        backOnline: "Back online",
        selectReportLabel: "Report selection",
        [utils.report.templateCodes.COORDINATION_NOTE]: "Coordination note",
        [utils.report.templateCodes.COMMON_INSPECTION_REPORT]: "Common inspection report",
      },
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-content {
  white-space: pre-line;
}

.offline-reports-list {
  background: #f8f9ff;
  border: 1px solid #eceef3;
  border-radius: 4px;
}

::v-deep .v-btn.text-none {
  text-transform: none !important;
}
::v-deep .v-alert__content {
  padding-top: 4px !important;
  font-family: Roboto,sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;

  p {
    color: var(--socotec-primary-socotec-black-regular, #333);
    font-weight: 400;
  }
}
.card_title--lg {
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 32px !important;
}
</style>
