const OperationRegistryIssuedDocuments = () =>
  import(
    /* webpackChunkName: "OperationRegister" */ "@/components/rapsosps/operations/operation/operation-registry/OperationRegistryIssuedDocuments"
  );
const OperationRegistryReceivedDocuments = () =>
  import(
    /* webpackChunkName: "OperationRegistryReceivedDocuments" */ "@/components/rapsosps/operations/operation/operation-registry/OperationRegistryReceivedDocuments"
  );
const OperationRegistryContributors = () =>
  import(
    /* webpackChunkName: "OperationRegistryContributors" */ "@/components/rapsosps/operations/operation/operation-registry/OperationRegistryContributors"
  );
const OperationRegistryIncumbentCompanies = () =>
  import(
    /* webpackChunkName: "OperationRegistryIncumbentCompanies" */ "@/components/rapsosps/operations/operation/operation-registry/OperationRegistryIncumbentCompanies"
  );

export default [
  {
    path: "",
    name: "OperationRegistryIssuedDocuments",
    component: OperationRegistryIssuedDocuments,
  },
  {
    path: "received",
    name: "OperationRegistryReceivedDocuments",
    component: OperationRegistryReceivedDocuments,
  },
  {
    path: "contributors",
    name: "OperationRegistryContributors",
    component: OperationRegistryContributors,
  },
  {
    path: "companies",
    name: "OperationRegistryIncumbentCompanies",
    component: OperationRegistryIncumbentCompanies,
  },
];
