import { Project } from "@socotec.io/socio-vue-components";

export default class Operation extends Project {
    static entity = "operations";
    static baseEntity = super.entity;

    static getProjectPhaseText(value) {
        return this.PROJECT_PHASE()[value];
    }

    static fields() {
        return {
            ...super.fields(),
            isMissionShown: this.boolean(false),
            isSpaceForPersonalProvided: this.boolean(false),
            isSpaceForPersonalProvidedNotes: this.string(""),
            plannedVisitCount: this.attr(0),
            companyCount: this.attr(0),
            cissctMeetingCount: this.attr(0),
            conceptionMeetingCount: this.attr(0),
            conceptionDuration: this.attr(0),
            realisationMeetingCount: this.attr(0),
            realisationDuration: this.attr(0),
            isPgc: this.boolean(false),
            isDiuo: this.boolean(false),
            createdAt: this.attr(""),
            pdpDescription: this.string(""),
            worksOver400Hours: this.boolean(false),
            worksOver90000Hours: this.boolean(false),
            dangerousWorks: this.boolean(false),
            dangerousWorksDescription: this.string(""),
            pdpWorkhours: this.string(""),   
        };
    }
    get isV1() {
        return Boolean(this.oldProjectId);
    }
}