<template>
  <VAutocomplete
    v-bind="$attrs"
    v-on="$listeners"
    :value="value"
    :items="itemsList"
    :loading="loading"
    chips
    small-chips
    no-filter
    return-object
    hide-details
    outlined
    dense
    deletable-chips
    @update:search-input="triggerSearch"
  >
  </VAutocomplete>
</template>

<script>
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    searchFunction: {
      type: Function,
      required: true,
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      loading: false,
      items: [],
    };
  },

  watch: {
    filters(newFilters, prevFilters) {
      if (!isEqual(newFilters, prevFilters)) {
        this.triggerSearch("");
      }
    },
  },

  computed: {
    itemsList() {
      if (!this.value) {
        return this.items;
      }
      return [...this.items, this.value];
    },
  },

  methods: {
    triggerSearch: debounce(async function (text) {
      if (text === null) return;
      this.loading = true;
      try {
        this.items = await this.searchFunction({
          searchText: text,
          filters: this.filters,
        });
        this.$emit('input', text);
      } catch (e) {
        // pass
      } finally {
        this.loading = false;
      }
    }, 1000),
  },
};
</script>
