import { Observation as BaseObservation } from "@socotec.io/socio-vue-components";

export class Observation extends BaseObservation {
  static entity = "extendedObservation";
  static baseEntity = super.entity;

  static fields() {
    return {
      ...super.fields(),
      isTousCorpsEtat: this.boolean(false),
    };
  }
}