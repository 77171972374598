const OperationConfigDuplicationDefault = () =>
  import(
    /* webpackChunkName: "OperationConfigDuplicationDefault" */ "@/components/rapsosps/operations/operation/operation-config/OperationConfigDuplicationDefault"
  );

const OperationConfigHandoverDefault = () =>
  import(
    /* webpackChunkName: "OperationConfigDuplicationDefault" */ "@/components/rapsosps/operations/operation/operation-config/OperationConfigHandoverDefault"
  );

export default [
  {
    path: "",
    name: "operation-config-duplication-default",
    component: OperationConfigDuplicationDefault,
    meta: {
      requiresCurrentUserManagerOrSubstitute: true
    }
  },
  {
    path: "handover",
    name: "operation-config-handover-default",
    component: OperationConfigHandoverDefault,
    meta: {
      requiresCurrentUserManagerOrSubstitute: true
    }
  },
];
