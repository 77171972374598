import { socioGrpcClient } from "@/setup/socioGrpcClient";

const state = {
  currentOverridenLastCrs: null
};

const getters = {};

const actions = {
  async fetchOverridenLastCr({ commit }, metadata) {
    const request = new socioGrpcClient.rapsosps_back.projects.OverridenLastCrListRequest();
    const response = await socioGrpcClient.rapsosps_back.projects.OverridenLastCrControllerPromiseClient.utils.listAllObjects(request, metadata)
    commit("SET_CURRENT_OVERRIDEN_LAST_CRS", response);
    return response;
  },

  async createOverridenLastCr({commit}, { projectId, company, date}) {
    const dateParts = date.split('/');
    const formatedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    const now = new Date().toISOString()

    const request = new socioGrpcClient.rapsosps_back.projects.OverridenLastCrRequest();
    request.setProject(projectId);
    request.setCompany(company);
    request.setDate(formatedDate);
    request.setCreatedAt(now);
    request.setUpdatedAt(now);

    const response = await socioGrpcClient.rapsosps_back.projects.OverridenLastCrControllerPromiseClient.create(request, {});

    commit("ADD_CURRENT_OVERRIDEN_LAST_CRS", response.toObject());
    
    return response.toObject();
  },

  async updateOverridenLastCr({commit}, { uuid, projectId, company, date}) {
    const dateParts = date.split('/');
    const formatedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    const now = new Date().toISOString()
    
    const request = new socioGrpcClient.rapsosps_back.projects.OverridenLastCrRequest();
    request.setProject(projectId);
    request.setCompany(company);
    request.setDate(formatedDate);
    request.setCreatedAt(now);
    request.setUpdatedAt(now);
    request.setUuid(uuid);
    
    const response = await socioGrpcClient.rapsosps_back.projects.OverridenLastCrControllerPromiseClient.update(request, {});
    
    commit("UPDATE_CURRENT_OVERRIDEN_LAST_CRS", response.toObject());

    return response.toObject();
  },
};

const mutations = {
  SET_CURRENT_OVERRIDEN_LAST_CRS: (state, overridenLastCr) => {
    state.currentOverridenLastCrs = overridenLastCr;
  },

  ADD_CURRENT_OVERRIDEN_LAST_CRS: (state, overridenLastCr) => {
    state.currentOverridenLastCrs.push(overridenLastCr);
  },

  UPDATE_CURRENT_OVERRIDEN_LAST_CRS: (state, overridenLastCr) => {
    const itemIndex = state.currentOverridenLastCrs.findIndex(
      ({ uuid }) => overridenLastCr.uuid === uuid
    );

    if (itemIndex < 0) {
      state.currentOverridenLastCrs.push(overridenLastCr);
    } else {
      state.currentOverridenLastCrs[itemIndex] = overridenLastCr;
      state.currentOverridenLastCrs = [...state.currentOverridenLastCrs];
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
