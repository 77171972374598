import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { Company } from "@socotec.io/socio-vue-components";

const state = {};

const getters = {};

const actions = {
  async createCompany(context, company) {
    const request =
      new socioGrpcClient.contributors.contributors.CompanyRequest();
    request.setTitle(company.title);
    request.setSiren(company.siren);
    request.setNic(company.nic);
    request.setAddress(company.address);
    request.setPostalCode(company.postalCode);
    request.setCity(company.city);
    const response =
      await socioGrpcClient.contributors.contributors.CompanyControllerPromiseClient.create(
        request,
        {}
      );

    const newCompany = new Company(response.toObject());
    newCompany.$save();
    return newCompany;
  },

  async updateCompany(context, company) {
    const request =
      new socioGrpcClient.contributors.contributors.CompanyRequest();
    request.setUuid(company.uuid);
    request.setTitle(company.title);
    request.setSiren(company.siren);
    request.setNic(company.nic);
    request.setAddress(company.address);
    request.setPostalCode(company.postalCode);
    request.setCity(company.city);

    const response =
      await socioGrpcClient.contributors.contributors.CompanyControllerPromiseClient.update(
        request,
        {}
      );

    const updatedCompany = new Company(response.toObject());

    return updatedCompany;
  },

  async searchCompany(context, { searchText, filters }) {
    const metadata = {
      filters: JSON.stringify({ search: searchText, ...filters }),
    };
    const request =
      new socioGrpcClient.contributors.contributors.CompanyListRequest();

    const response =
      await socioGrpcClient.contributors.contributors.CompanyControllerPromiseClient.list(
        request,
        metadata
      );
    
    return response.toObject().resultsList.map((c) => new Company(c));
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
