// const CaseDashboard = () =>
//   import(
//     /* webpackChunkName: "CaseDashboard" */ "@/components/rapsosps/cases/case/CaseDashboard"
//   );

const CaseIdentityCard = () =>
  import(
    /* webpackChunkName: "CaseIdentityCard" */ "@/components/rapsosps/cases/case/CaseIdentityCard"
  );

import CaseIdentityCardChildren from "./childrens/case-identity-card-children";

const CaseRegister = () =>
  import(
    /* webpackChunkName: "CaseRegister" */ "@/components/rapsosps/cases/case/CaseRegister"
  );

import CaseRegisterChildren from "./childrens/case-registry-children";

const CaseDetail = () =>
  import(
    /* webpackChunkName: "CaseDetail" */ "@/components/rapsosps/cases/case/CaseDetail"
  );

const CaseContributors = () =>
  import(
    /* webpackChunkName: "CaseContributors" */ "@/components/rapsosps/cases/case/CaseContributors"
  );

const CaseDocuments = () =>
  import(
    /* webpackChunkName: "CaseDocuments" */ "@/components/rapsosps/cases/case/CaseDocuments"
  );

import CaseDocumentsChildren from "./childrens/case-documents-children";

export default [
  // TODO: Temporary deactivation of the dashboard -> Léo
  // {
  //   path: "",
  //   name: "case-dashboard",
  //   component: CaseDashboard,
  // },
  {
    path: "identity-card",
    component: CaseIdentityCard,
    children: CaseIdentityCardChildren,
  },
  {
    path: "registry",
    component: CaseRegister,
    children: CaseRegisterChildren,
  },
  {
    path: "details",
    name: "case-detail",
    component: CaseDetail,
  },
  {
    path: "contributors",
    name: "case-contributors",
    component: CaseContributors,
  },
  {
    path: "documents",
    component: CaseDocuments,
    children: CaseDocumentsChildren,
  },
];
