import { socioGrpcClient } from "@/setup/socioGrpcClient";


const notificationClient = socioGrpcClient.notification_management.notification;

const state = {};

const getters = {};

const actions = {
  async fetchUserNotificationEmailStatus(context, objectRelatedUuid) {
    const request = socioGrpcClient.javascriptToRequest(
      notificationClient.UserNotificationFetchUserEmailStatusRequest,
      { "objectRelatedUuid": objectRelatedUuid },
    );
    const response =
      await notificationClient.UserNotificationControllerPromiseClient.fetchUserEmailStatus(
        request,
        {}
      );
    const results = response.getResultsList().map((userNotif) => {
      return {
        ...userNotif.toObject(),
        extraData: userNotif.getExtraData()?.toJavaScript()
      };
    })
    return results;
  },
  async resendUserNotificationsEmail(_, { objectRelatedUuid, usermanagementUuids }) {
    const request = new notificationClient.NotificationReSendNotificationRequest();
    request.setObjectRelatedUuid(objectRelatedUuid);
    request.setRecipientUsersList(usermanagementUuids);
    return await notificationClient.NotificationControllerPromiseClient.reSendNotification(
      request,
      {}
    );
  },

};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
