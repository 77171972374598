import {CONTRIBUTORS_ROLES} from "@socotec.io/socio-vue-components";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import {MISSIONS } from "@/utils/consts/missions";
import ExtendedRole from "@/models/ExtendedRole";

const state = {};

const getters = {
  /**
   * Get roles within Vuex Store
   * @returns {Report[]}
   */
  getRoles() {
    return ExtendedRole.all();
  },
  getRolesForGivenMission: () => (missionCode, SERVICE_ID=null) => {
    if(SERVICE_ID){
      if(missionCode === MISSIONS.KDAD ){
        return ExtendedRole.query().where("serviceId", SERVICE_ID)
            .where(role => role.name !== CONTRIBUTORS_ROLES.MAITRE_OUVRAGE && role.name !== CONTRIBUTORS_ROLES.ENTREPRISE)
            .get();
      }
      return ExtendedRole.query().where("serviceId", SERVICE_ID)
          .where((role) => role.name !== CONTRIBUTORS_ROLES.ENTREPRISE_UTILISATRICE && role.name !== CONTRIBUTORS_ROLES.ENTREPRISE_EXTERIEURE)
          .get();
    }else{
      if(missionCode === MISSIONS.KDAD ){
        return ExtendedRole.query()
            .where(role => role.name !== CONTRIBUTORS_ROLES.MAITRE_OUVRAGE && role.name !== CONTRIBUTORS_ROLES.ENTREPRISE)
            .get();
      }
      return ExtendedRole.query()
          .where((role) => role.name !== CONTRIBUTORS_ROLES.ENTREPRISE_UTILISATRICE && role.name !== CONTRIBUTORS_ROLES.ENTREPRISE_EXTERIEURE)
          .get();
    }
  }
};

const actions = {
  async fetchRoles(_, { serviceId, metadata }) {
    const requestMetadata = {
      filters: JSON.stringify({
        service_id: serviceId,
      }),
      ...metadata,
    };
    const request =
      new socioGrpcClient.contributors.contributors.RoleListRequest();

    const response =
      await socioGrpcClient.contributors.contributors.RoleControllerPromiseClient.list(
        request,
        requestMetadata
      );
    return ExtendedRole.insert({ data: response.toObject().resultsList });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
