import { Model } from "@vuex-orm/core";

export default class OverridenLastCr extends Model {
  static entity = "OverridenLastCr";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.uid(""),
      project: this.attr(),
      company: this.attr(),
      date: this.attr(),
    };
  }
}
