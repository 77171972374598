const state = {
  loading: false,
  progressCSV: 0,
};

const getters = {
  isLoading: (state) => state.loading,
  progress: (state) => state.progressCSV,
};

const actions = {};

const mutations = {
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_PROGRESS: (state, progress) => {
    state.progressCSV = progress;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
