import { Batch, DefaultBatch } from "@socotec.io/socio-vue-components";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { SERVICE_ID } from "@/constants";
const SET_BATCHES_COUNT = "SET_BATCHES_COUNT";
const SET_DEFAULT_BATCHES_LOADED = "SET_DEFAULT_BATCHES_LOADED";
const ADD_BATCHES_COUNT = "ADD_BATCHES_COUNT";
const SET_PROJECT_BATCHES_LOADED = "SET_PROJECT_BATCHES_LOADED";

const state = {
  projectBatchesCount: 0,
  isDefaultBatchLoaded: false,
  isProjectBatchLoaded: false,
};

const getters = {
  getProjectBatchesCount: (state) => {
    return state.projectBatchesCount;
  },
  isDefaultBatchLoaded: (state) => {
    return state.isDefaultBatchLoaded;
  },
  isProjectBatchLoaded: (state) => {
    return state.isProjectBatchLoaded;
  },
};

const actions = {
  // FETCH
  async fetchDefaultBatches({ commit }, filtersData) {
    try {
      commit(SET_DEFAULT_BATCHES_LOADED, false);
      const request =
        new socioGrpcClient.batch_management.batch.BatchListRequest();
      const response =
        await socioGrpcClient.batch_management.batch.BatchControllerPromiseClient.list(
          request,
          {
            filters: JSON.stringify({
              service_id: SERVICE_ID,
              ...filtersData,
            }),
          }
        );
      commit(SET_DEFAULT_BATCHES_LOADED, true);
      return DefaultBatch.insert({
        data: response.toObject().resultsList,
      });
    } catch (err) {
      console.error("Error while fetching default batches: ", err);
    }
  },
  async fetchProjectBatches({ commit }, metadata) {
    try {
      commit(SET_DEFAULT_BATCHES_LOADED, false);
      commit(SET_PROJECT_BATCHES_LOADED, false);
      const request =
        new socioGrpcClient.batch_management.batch.CustomizedBatchListRequest();
      const response =
        await socioGrpcClient.batch_management.batch.CustomizedBatchControllerPromiseClient.list(
          request,
          metadata
        );
      const resultList = response.toObject().resultsList;

      commit(SET_BATCHES_COUNT, resultList.length);

      commit(SET_PROJECT_BATCHES_LOADED, true);

      const dataToRecord = resultList.map((batch) => ({
        uuid: batch.uuid,
        objectId: batch.objectId,
        overridedLabel: batch.overridedLabel,
        code: batch.code,
      }));

      return Batch.insert({
        data: dataToRecord,
      });
    } catch (err) {
      console.error("Error while fetching project batches: ", err);
    }
  },
  // SET
  async createBatch({ commit }, batchToCreate) {
    batchToCreate.isActivated = true;
    try {
      const request = socioGrpcClient.javascriptToRequest(
        socioGrpcClient.batch_management.batch.CustomizedBatchRequest,
        batchToCreate,
        ["$id", "uuid", "entreprise"]
      );
      const response =
        await socioGrpcClient.batch_management.batch.CustomizedBatchControllerPromiseClient.create(
          request,
          {}
        );
      const newBatch = response.toObject();
      commit(ADD_BATCHES_COUNT, 1);
      const dataInserted = await Batch.insert({
        data: newBatch,
      });
      return dataInserted;
    } catch (err) {
      console.error("Error while creating batch: ", err);
    }
  },

  async updateBatch(_, batchToUpdate) {
    batchToUpdate.isActivated = true;
    try {
      const request = socioGrpcClient.javascriptToRequest(
        socioGrpcClient.batch_management.batch.CustomizedBatchRequest,
        batchToUpdate,
        ["$id", "entreprise"]
      );
      const response =
        await socioGrpcClient.batch_management.batch.CustomizedBatchControllerPromiseClient.update(
          request,
          {}
        );
      const updatedBatch = response.toObject();
      const dataUpdated = await Batch.update({
        where: batchToUpdate.uuid,
        data: updatedBatch,
      });
      return dataUpdated.uuid;
    } catch (err) {
      console.error("Error while updating batch: ", err);
    }
  },

  async deleteBatch({ commit }, { batch, projectUuid }) {
    try {
      let request =
        new socioGrpcClient.batch_management.batch.CustomizedBatchRequest();
      request.setUuid(batch.uuid);

      await socioGrpcClient.batch_management.batch.CustomizedBatchControllerPromiseClient.destroy(
        request,
        {}
      );
      commit(ADD_BATCHES_COUNT, -1);
      Batch.delete(batch.uuid);

      request =
        new socioGrpcClient.rapsosps_back.referencial.UnlinkBatchRequest();
      request.setBatchUuid(batch.uuid);
      request.setProjectUuid(projectUuid);

      await socioGrpcClient.rapsosps_back.referencial.PgcNodeControllerPromiseClient.unlinkBatch(
        request,
        {}
      );
    } catch (err) {
      console.error("Error while deleting batch: ", err);
    }
  },
};

const mutations = {
  [SET_BATCHES_COUNT]: (state, newTotal) => {
    state.projectBatchesCount = newTotal;
  },
  [SET_DEFAULT_BATCHES_LOADED]: (state, value) => {
    state.isDefaultBatchLoaded = value;
  },
  [ADD_BATCHES_COUNT]: (state, number) => {
    state.projectBatchesCount += number;
  },
  [SET_PROJECT_BATCHES_LOADED]: (state, value) => {
    state.isProjectBatchLoaded = value;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
