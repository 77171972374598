import store from "@/store";
import { User } from "@socotec.io/socio-vue-components";
import moment from "moment";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import google_protobuf_empty_pb from "google-protobuf/google/protobuf/empty_pb.js";

const initOfflineMode = async () => {
  const user = await store.getters["offlineReports/getCurrentUser"];
  const endValidityDate = moment().subtract(6, "week");
  const lastLoginDate = moment(user.lastLogin);
  if (
    lastLoginDate > endValidityDate &&
    user &&
    user.groups.includes("SocotecUser")
  ) {
    await User.insert({ data: user });
  } else {
    throw Error("Unauthorized access to offline mode");
  }
};

export default async (to, from, next) => {
  let isOnline = window.navigator.onLine;
  try {
      await socioGrpcClient.rapsosps_back.healthcheck.HealthControllerPromiseClient.check(
        new google_protobuf_empty_pb.Empty(),
        {}
      );
  }
  catch (error) {
    isOnline = false;
    store.dispatch("network/setNetworkState", false);
    store.dispatch("network/setOnlineModal", true);
  }
  if (isOnline) {
    await store.dispatch("user/initializeCurrentUser");
    await store.dispatch(
      "offlineReports/setCurrentUser",
      store.getters["user/getCurrentUser"]
    );
  } else {
    try {
      await initOfflineMode();
    } catch {
      return next({ name: "unauthorized-access-offline-mode" });
    }
  }
  next();
};
