const CaseIdentityCardDefault = () =>
  import(
    /* webpackChunkName: "CaseIdentityCardDefault" */ "@/components/rapsosps/cases/case/case-identity-card/CaseIdentityCardDefault"
  );
const CaseIdentityCardRelation = () =>
  import(
    /* webpackChunkName: "CaseIdentityCardRelation" */ "@/components/rapsosps/cases/case/case-identity-card/CaseIdentityCardRelation"
  );

export default [
  {
    path: "",
    name: "CaseIdentityCardDefault",
    component: CaseIdentityCardDefault,
  },
  {
    path: "relation",
    name: "CaseIdentityCardRelation",
    component: CaseIdentityCardRelation,
  },
];
