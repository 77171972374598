import { socioGrpcClient } from "@/setup/socioGrpcClient";
import {
  ReferencialNode,
  RapsoSPSNode,
  utils,
} from "@socotec.io/socio-vue-components";
import DiuoExtra from "@/models/DIUOExtra";
import Pgc from "@/models/PGC";
import Diuo from "@/models/DIUO";
import { renameKeys } from "@/utils/utilsGrcpRest";
import {
  formatModelName,
  metadataAsString,
  flatNodes,
  setConditionList,
  conditionalsListToObject,
  grpcPropertiesToRename,
} from "@/utils/referencialService";

const socioReferencialUtils = utils.referencialUtils;

const state = {
  referencialVersion: {
    diuo: null,
    pgc: null,
    initialConception: null,
  },
};

const getters = {
  getTree:
    () =>
      (referencial, displayIsArchived = true) => {
        return ReferencialNode.query()
          .where((node) => {
            if (!displayIsArchived && node.isArchived) return false;
            return (
              node.referencialName === referencial && node.isDisplay === true
            );
          })
          .with("spsNode")
          .get()
          .sort((a, b) => {
            return a.path.localeCompare(b.path, undefined, {
              numeric: true,
              sensitivity: "base",
            });
          });
      },
  getDiuoExtra: () => (diuoNode, diuoType) => {
    return DiuoExtra.query()
      .where((node) => {
        return node.diuoNode === diuoNode && node.diuoType === diuoType;
      })
      .get();
  },
  getDocumentAttachedUuid: () => (files) => {
    if (!files || files.length === 0) return [];
    let fileUuids = files.map((file) => file.uuid);
    return fileUuids;
  },
  getReferencialVersion: (state) => (referencialName) => {
    return state.referencialVersion[referencialName];
  },
  getOtherNodeDataAttached: () => (node) => {
    if (node.referencialName === "pgc") {
      return Pgc.query().where("spsNode", node.spsNode.uuid).first();
    } else {
      return Diuo.query().where("spsNode", node.spsNode.uuid).first();
    }
  },
  isReferencialNodesFetched:
    (state, getters, rootState) => (referencialName) => {
      return rootState.entities.referencialNodes[referencialName];
    },

  diuoParams: (state, getter, rootState, rootGetters) => {
    return {
      versionName: "CURRENT",
      versionNumber: 1,
      applicationName: "RAPSOSPS_FRANCE",
      referencialType: "PROJECT",
      referencialName: "RAPSOSPS_IU",
      projectUuid: rootGetters["operation/getOperationUuid"],
    };
  },
  pgcParams: (state, getter, rootState, rootGetters) => {
    return {
      versionName: "CURRENT",
      versionNumber: 1,
      applicationName: "RAPSOSPS_FRANCE",
      referencialType: "PROJECT",
      referencialName: "RAPSOSPS_OC",
      projectUuid: rootGetters["operation/getOperationUuid"],
    };
  },
};

const actions = {
  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤ LIST ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤

  async fetchReferencialVersion({ commit }, data) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services.VersionRequest,
      data.requestParams
    );
    const response =
      await socioGrpcClient.referencial.referencial_services.ReferencialNodeControllerPromiseClient.version(
        request,
        {}
      );
    commit("SET_REFERENCIAL_VERSION", {
      versionData: response.toObject(),
      referencialFetched: data.referencialFetched,
    });
  },

  async conditionalTitlestoJSON(context, dataResponse) {
    let list = [];
    if (dataResponse.getConditionalTitlesList) {
      dataResponse.getConditionalTitlesList().forEach((cT) => {
        list.push(cT.toJavaScript());
      });
    }
    return list;
  },

  async conditionalDisplaytoJSON(context, dataResponse) {
    let list = [];
    dataResponse.getConditionalDisplayList().forEach((cT) => {
      list.push(cT.toJavaScript());
    });
    return list;
  },

  async allConditionalsListToJSON({ dispatch }, referencialNodeResult) {
    const response = await conditionalsListToObject(
      { dispatch },
      referencialNodeResult.getResultsList()
    );
    return response;
  },
  async fetchNodes({ dispatch }, data) {
    await dispatch("fetchRapsoSPSNodes", data);
    await dispatch("fetchReferencialNodes", data);
  },
  async fetchReferencialNodes({ dispatch }, data) {
    const { metadata = {}, requestParams, referencialFetched } = data;

    const metadataStr = metadataAsString(metadata);

    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services.VersionCodesRequest,
      requestParams
    );
    const response =
      await socioGrpcClient.referencial.referencial_services.ReferencialNodeControllerPromiseClient.list(
        request,
        metadataStr
      );

    const results = await dispatch("allConditionalsListToJSON", response);

    let flattenNodes = flatNodes(results, referencialFetched);

    /* INFO - TT - 19/10/2021: In case where we apply search filter we need
    to delete initial referencial nodes and then insert referencial nodes fetched
    following filter from bakend. Cannot use create method of vuexorm model as
    this model is use by many components for others usages*/

    const { search } = metadata.filters || {};

    if (search) {
      await ReferencialNode.delete(
        (node) => node.referencialName === referencialFetched
      );
      flattenNodes = flattenNodes.map((nodes) => ({
        ...nodes,
        isOpen: true,
        isDisplay: true,
      }));
    }

    await ReferencialNode.insert({ data: flattenNodes });
    ReferencialNode.commit((state) => {
      state[data.referencialFetched] = true;
    });

    /* INFO - TT - 12/10/2021: Below code allows to fetch users data
     from their emails as they are necessary in referencial administration*/
    const modifierUsersUuid = await ReferencialNode.query()
      .where("modifiedByUuid", (value) => value !== "")
      .get()
      .map((referencialNode) => referencialNode.modifiedByUuid);
    const uniqueUsersUuids = new Set(modifierUsersUuid);
    if (uniqueUsersUuids.size !== 0) {
      await dispatch("user/getUsersFromUuids", Array.from(uniqueUsersUuids), {
        root: true,
      });
    }
  },

  async fetchRapsoSPSNodes(context, data) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services.VersionRequest,
      data.requestParams
    );
    const response = data.withSpsNodeTechnicalDoc
      ? await socioGrpcClient.referencial.referencial_services.RapsoSPSNodeControllerPromiseClient.listWithTechnicalDocs(
        request,
        {}
      )
      : await socioGrpcClient.referencial.referencial_services.RapsoSPSNodeControllerPromiseClient.list(
        request,
        {}
      );

    const results = response
      .toObject()
      .resultsList.map((res) => renameKeys(grpcPropertiesToRename, res));
    await RapsoSPSNode.insert({ data: results });
  },

  async fetchFlatFilteredReferencialNodes(
    context,
    { requestParams, metadata, insertData = true }
  ) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services.VersionCodesRequest,
      requestParams,
      ["rootNodeUuid", "customReferencialName"]
    );
    const response =
      await socioGrpcClient.referencial.referencial_services.ReferencialNodeControllerPromiseClient.flatList(
        request,
        metadata
      );

    if (!insertData) {
      return response.toObject().resultsList;
    }
    await ReferencialNode.insert({ data: response.toObject().resultsList });
  },

  // On rapsosps-back, fetch the nodes: DiuoNode, PgcNode, InitialConceptionNode
  async fetchSpsBackNodeEditedDatas({ dispatch }, { model, metadata }) {
    const modelBackendFormat = formatModelName(model.entity);
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.rapsosps_back.referencial[
      `${modelBackendFormat}ListRequest`
      ],
      {}
    );
    const response = await socioGrpcClient.rapsosps_back.referencial[
      `${modelBackendFormat}ControllerPromiseClient`
    ].list(request, metadata);

    const results = response
      .toObject()
      .resultsList.map((res) => renameKeys(grpcPropertiesToRename, res));

    await model.insert({ data: results });

    if (model.entity === "diuo") {
      await dispatch("fetchDiuoExtraDatas");
    }
  },

  async fetchDiuoExtraDatas({ dispatch, rootGetters }) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.rapsosps_back.referencial.DiuoNodeExtraListRequest,
      {}
    );
    const metadata = {
      filters: JSON.stringify({
        diuo_node__project_uuid: rootGetters["operation/getOperationUuid"],
      }),
    };
    const response =
      await socioGrpcClient.rapsosps_back.referencial.DiuoNodeExtraControllerPromiseClient.list(
        request,
        metadata
      );

    const results = response
      .toObject()
      .resultsList.map((res) => renameKeys(grpcPropertiesToRename, res));

    for (const diuoNodeExtra of results) {
      const diuoNodeExtraFiles = diuoNodeExtra.files.filter(
        (file) => file && file !== ""
      );
      if (diuoNodeExtraFiles.length === 0) continue;
      const documents = await dispatch(
        "fetchDocumentAttachedByUuid",
        diuoNodeExtraFiles.join(",")
      );
      diuoNodeExtra.files = documents;
    }
    await DiuoExtra.insert({ data: results });
  },

  async fetchDocumentAttachedByUuid({ dispatch }, files) {
    const metadata = {
      filters: JSON.stringify({
        uuid: files,
      }),
    };
    return await dispatch(
      "document/fetchDocumentList",
      { metadata, createData: false },
      {
        root: true,
      }
    );
  },

  async fetchReferencialNodeDescendants(
    context,
    { params = {}, metadata = {} }
  ) {
    const client =
      socioGrpcClient.referencial.referencial_services
        .ReferencialNodeControllerPromiseClient;
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services.VersionRequest,
      params
    );

    Object.keys(metadata).forEach((key) => {
      if (typeof metadata[key] === "object") {
        metadata[key] = JSON.stringify(metadata[key]);
      } else {
        metadata[key] = metadata[key].toString();
      }
    });

    const response = await client.list(request, metadata);
    return response.toObject().resultsList;
  },

  async fetchReferencialNodeParents(context, { params = {}, metadata = {} }) {
    const client =
      socioGrpcClient.referencial.referencial_services
        .ReferencialNodeControllerPromiseClient;
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services
        .ReferencialNodeCodesRequest,
      params
    );
    const response = await client.getNodesWithParents(request, metadata);
    return response.toObject().resultsList;
  },

  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤ RETRIEVE ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤

  async retrieveReferencialNode(context, { uuid }) {
    const request =
      new socioGrpcClient.referencial.referencial_services.ReferencialNodeCodesRequest();
    request.setUuid(uuid);
    const response =
      await socioGrpcClient.referencial.referencial_services.ReferencialNodeControllerPromiseClient.retrieve(
        request,
        {}
      );
    const referencialNode = renameKeys(
      grpcPropertiesToRename,
      response.toObject()
    );
    return referencialNode;
  },

  async retrieveRapsoSPSNode(context, uuid) {
    const rapsospsNodeRequest = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services
        .RapsoSPSNodeRetrieveRequest,
      { uuid }
    );
    const rapsospsNodeResponse =
      await socioGrpcClient.referencial.referencial_services.RapsoSPSNodeControllerPromiseClient.retrieve(
        rapsospsNodeRequest,
        {}
      );

    const rapsospsNode = renameKeys(
      grpcPropertiesToRename,
      rapsospsNodeResponse.toObject()
    );
    return rapsospsNode;
  },

  async getModifiedReferencialNodes({ rootGetters }, params) {
    const client =
      socioGrpcClient.referencial.referencial_services
        .ReferencialNodeControllerPromiseClient;
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services
        .ReferencialNodeGetModifiedRequest,
      params
    );
    const currentUser = rootGetters["user/getCurrentUser"];
    request.setModifiedByUuid(currentUser.usermanagementUuid);
    const response = await client.getModified(request, {});
    return response.toObject().resultsList;
  },

  async getLastReferencialExportDate(context, params) {
    const client =
      socioGrpcClient.referencial.referencial_services
        .CSVExportReferencialControllerPromiseClient;

    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services.VersionRequest,
      params
    );

    const response = await client.getDateLastReferencialExport(request, {});

    return response.getExportDate();
  },

  async getReferencialLastUpdateDate(context, params) {
    const excludedField = [
      "versionName",
      "versionNumber",
      "referencialType",
      "projectUuid",
    ];
    const client =
      socioGrpcClient.referencial.referencial_services
        .ReferencialUpdateControllerPromiseClient;

    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services
        .ReferencialUpdateLastUpdateRequest,
      params,
      excludedField
    );
    const response = await client.lastUpdate(request, {});
    return response.toObject().lastUpdated;
  },

  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤ CREATE / UPDATE ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤

  async createReferencialNode({ rootGetters, dispatch }, referencialNode) {
    let rapsoSpsNode = null;
    const excludedField = [
      "$id",
      "referencialName",
      "spsNode",
      "isDisplay",
      "isOpen",
      "conditionalTitles",
      "conditionalDisplay",
      "rapsotecNode",
      "children",
      "uuid",
      "createdAt",
      "updatedAt",
      "risks",
      "accesses",
      "measures",
      "templateSentences",
      "typologies",
      "iuSentences",
      "ocSentences",
      "securityDispositions",
      "mandatoryMissions",
      "path",
      "level",
      "numchild",
      "readablePath",
      "childrenList",
    ];

    if (referencialNode.objectId) {
      const nationalRapsoSPSNode = await dispatch(
        "retrieveRapsoSPSNode",
        referencialNode.objectId
      );
      const rapsoSpsNodeEmpty = await dispatch(
        "createRapsoSPSNode",
        nationalRapsoSPSNode
      );
      rapsoSpsNode = await dispatch(
        "retrieveRapsoSPSNode",
        rapsoSpsNodeEmpty.uuid
      );
    } else {
      rapsoSpsNode = await dispatch(
        "createRapsoSPSNode",
        referencialNode.spsNode || {}
      );
    }

    const rapsoSpsNodesInserted = await RapsoSPSNode.insert({
      data: rapsoSpsNode,
    });
    referencialNode.objectId = rapsoSpsNode.uuid;

    const referencialNodeRequest = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services.ReferencialNodeRequest,
      referencialNode,
      excludedField
    );

    referencialNodeRequest.setModifiedByUuid(
      rootGetters["user/getCurrentUser"].usermanagementUuid
    );

    const referencialNodeResponse =
      await socioGrpcClient.referencial.referencial_services.ReferencialNodeControllerPromiseClient.create(
        referencialNodeRequest,
        {}
      );
    const result = referencialNodeResponse.toObject();

    result.spsNode = rapsoSpsNodesInserted.rapsoSPSNodes[0];
    result.referencialName = referencialNode.referencialName;
    result.isDisplay = true;

    await ReferencialNode.insert({
      data: result,
    });
    // Update the children/numchild of the parent node to have the toggleExpandBtn displayed in UI
    const parentNode = await socioReferencialUtils.getParentNode(result);
    if (parentNode) {
      socioReferencialUtils.updateNodeChildren(parentNode);
      parentNode.isOpen = true;
      parentNode.$save();
    }
  },

  async createRapsoSPSNode(context, rapsoSpsNode) {
    const excludedField = ["uuid", "createdAt", "updatedAt"];
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services.RapsoSPSNodeRequest,
      rapsoSpsNode,
      excludedField
    );
    const response =
      await socioGrpcClient.referencial.referencial_services.RapsoSPSNodeControllerPromiseClient.create(
        request,
        {}
      );
    return response.toObject();
  },

  async updateRapsoSPSNode(context, rapsoSpsNode) {
    const excludedField = [
      "createdAt",
      "referencialNodeDatas",
      "referencialNode",
      "missionCodes",
      "technicalDocsData",
      "$id",
    ];
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services.RapsoSPSNodeRequest,
      rapsoSpsNode,
      excludedField
    );

    const response =
      await socioGrpcClient.referencial.referencial_services.RapsoSPSNodeControllerPromiseClient.update(
        request,
        {}
      );

    await RapsoSPSNode.update({
      where: rapsoSpsNode.uuid,
      data: response.toObject(),
    });
  },

  async saveReferencialNode({ rootGetters }, referencialNode) {
    // Fields that are not in the grpc message or only in vuex-orm model
    const excludedField = [
      "$id",
      "referencialName",
      "spsNode",
      "isDisplay",
      "isOpen",
      "conditionalTitles",
      "conditionalDisplay",
      "rapsotecNode",
      "children",
      "risks",
      "accesses",
      "measures",
      "templateSentences",
      "typologies",
      "isSelected",
      "iuSentences",
      "ocSentences",
      "securityDispositions",
      "mandatoryMissions",
      "path",
      "level",
      "numchild",
      "readablePath",
      "modificationOf",
      "updatedAt",
    ];
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services.ReferencialNodeRequest,
      referencialNode,
      excludedField
    );
    setConditionList(referencialNode, "conditionalTitles", request);
    setConditionList(referencialNode, "conditionalDisplay", request);

    request.setModifiedByUuid(
      rootGetters["user/getCurrentUser"].usermanagementUuid
    );
    const response =
      await socioGrpcClient.referencial.referencial_services.ReferencialNodeControllerPromiseClient.update(
        request,
        {}
      );
    await ReferencialNode.update({
      where: referencialNode.uuid,
      data: {
        label: response.toObject().label,
        description: response.toObject().description,
        modifiedByUuid: response.toObject().modifiedByUuid,
      },
    });
  },

  // On rapsosps-back, save the DiuoNode, PgcNode, InitialbConceptionNode
  async saveSpsBackNode({ getters }, data) {
    // Fields that are not in the model backend
    const excludedField = [
      "$id",
      "spsNodeDatas",
      "diuoExtra",
      "label",
      "description",
    ];
    const fileUuids = getters.getDocumentAttachedUuid(data.form.files);
    const modelBackendFormat = formatModelName(data.model.entity);
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.rapsosps_back.referencial[modelBackendFormat],
      {
        ...data.form,
        objectStatus: data.form.objectStatus ?? "",
        spsNode: data.spsNodeUuid,
        projectUuid: data.projectUuid,
        files: fileUuids,
      },
      excludedField
    );

    let result = null;
    if (request.getUuid()) {
      const response = await socioGrpcClient.rapsosps_back.referencial[
        `${modelBackendFormat}ControllerPromiseClient`
      ].update(request, {});
      result = response.toObject();
    } else {
      const response = await socioGrpcClient.rapsosps_back.referencial[
        `${modelBackendFormat}ControllerPromiseClient`
      ].create(request, {});
      result = response.toObject();
      data.form.uuid = result.uuid;
    }
    result = renameKeys(grpcPropertiesToRename, result);
    return await data.model.insertOrUpdate({
      data: result,
    });
  },

  async saveDiuoExtraDatas({ getters, rootGetters }, data) {
    const fileUuids = getters.getDocumentAttachedUuid(data.files);
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.rapsosps_back.referencial.DiuoNodeExtra,
      { ...data, files: fileUuids },
      ["$id"]
    );

    // Add project_uuid in metadata because permissions in backend need it
    const metadata = {
      filters: JSON.stringify({
        project_uuid: rootGetters["operation/getOperationUuid"],
      }),
    };

    let result = null;
    if (request.getUuid()) {
      const response =
        await socioGrpcClient.rapsosps_back.referencial.DiuoNodeExtraControllerPromiseClient.update(
          request,
          metadata
        );
      result = response.toObject();
    } else {
      const response =
        await socioGrpcClient.rapsosps_back.referencial.DiuoNodeExtraControllerPromiseClient.create(
          request,
          metadata
        );
      result = response.toObject();
    }

    result = renameKeys(grpcPropertiesToRename, result);
    result.files = data.files;
    await DiuoExtra.insertOrUpdate({ data: result });
  },

  async createProjectReferencial(context, requestParams) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services
        .CreateProjectReferencialRequest,
      requestParams
    );
    await socioGrpcClient.referencial.referencial_services.ReferencialNodeControllerPromiseClient.createProjectReferencial(
      request,
      {}
    );
  },

  async updateReferencialVersionName({ commit }, data) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services
        .ReferencialNodeUpdateVersionNameRequest,
      data.requestParams
    );
    const response =
      await socioGrpcClient.referencial.referencial_services.ReferencialNodeControllerPromiseClient.updateVersionName(
        request,
        {}
      );
    commit("SET_CUSTOM_REFERENCIAL_NAME", {
      versionData: response.toObject(),
      referencialFetched: data.referencialFetched,
    });
  },

  async moveReferencialNode({ rootGetters }, { referencialNodeUuid, form }) {
    const client =
      socioGrpcClient.referencial.referencial_services
        .ReferencialNodeControllerPromiseClient;
    const request =
      new socioGrpcClient.referencial.referencial_services.ReferencialNodeUpdateMoveRequest();
    request.setTarget(form.target);
    request.setCode(form.code);
    request.setUuid(referencialNodeUuid);
    const user = rootGetters["user/getCurrentUser"];
    request.setModifiedByUuid(user.usermanagementUuid);
    return client.updateMove(request, {});
  },

  async postDraftToCurrent({ rootGetters }, data) {
    const client =
      socioGrpcClient.referencial.referencial_services
        .ReferencialUpdateControllerPromiseClient;
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services
        .ReferencialUpdatePostDraftToCurrentRequest,
      data
    );
    const user = rootGetters["user/getCurrentUser"];
    request.setMigratedByUuid(user.usermanagementUuid);
    const response = await client.postDraftToCurrent(request, {});
    return response;
  },

  async duplicateNodeWithChildren({ state, dispatch }, requestData) {
    const params = {
      ...requestData,
      nodeToCopyUuid: requestData.nodeToCopy.uuid,
      referencialName: requestData.nodeToCopy.referencialName,
    };
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services
        .ReferencialNodeDuplicateNodeRequest,
      params,
      ["referencialName", "nodeToCopy"]
    );
    const response =
      await socioGrpcClient.referencial.referencial_services.ReferencialNodeControllerPromiseClient.duplicateNode(
        request,
        {}
      );
    const nodes = response.toObject().resultsList;
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      // Display the first parent node duplicated
      if (i === 0) {
        node.isDisplay = true;
      }
      // Set numchild to node to have the toggleExpandBtn displayed in UI
      if (nodes[i + 1] && nodes[i + 1].level > node.level) {
        node.numchild = nodes.length - (i + 1);
      }
      // MS - INFO - 13/10/2021 - perf can be improved if we fetch all RapsoSPSNode in one call
      let rapsoSPSNode = await dispatch("retrieveRapsoSPSNode", node.objectId);
      rapsoSPSNode.referencialNode = node.uuid;
      rapsoSPSNode = new RapsoSPSNode(rapsoSPSNode);
      node.spsNode = rapsoSPSNode;
      node.referencialName = requestData.nodeToCopy.referencialName;
    }
    const newInserted = await ReferencialNode.insert({ data: nodes });

    // Update the children/numchild of the new duplicated referencial nodes,
    // MS - INFO - 22/11/2021 - we can avoid  this if backend serializer return the node updated numchild and children array
    for (const node of newInserted.referencialNodes) {
      socioReferencialUtils.updateNodeChildren(node);
      const parentNode = await socioReferencialUtils.getParentNode(node);
      if (parentNode) {
        socioReferencialUtils.updateNodeChildren(parentNode);
      }
    }

    // Duplicate sps-back Diuo nodes attached
    const requestParams = {
      ...state.referencialVersion[requestData.nodeToCopy.referencialName],
      parentNode: requestData.nodeToCopy.uuid,
    };
    delete requestParams.uuid;

    let sourceNodes = await dispatch("fetchFlatFilteredReferencialNodes", {
      requestParams,
      metadata: {},
      insertData: false,
    });
    const parentRootNode = await ReferencialNode.find(
      requestData.nodeToCopy.uuid
    );
    const sourceNodesUuid = sourceNodes.map((node) => node.objectId);
    sourceNodesUuid.unshift(parentRootNode.objectId);
    const targetNodesUuid = nodes.map((node) => node.objectId);
    await dispatch("duplicateDiuoNodes", {
      sourceNodesUuid,
      targetNodesUuid,
    });
  },

  async duplicateNodeReferencialWithChildren({ dispatch }, requestData) {
    const params = {
      ...requestData,
      nodeToCopyUuid: requestData.nodeToCopy.uuid,
      referencialName: requestData.nodeToCopy.referencialName,
    };
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services
        .ReferencialNodeDuplicateNodeRequest,
      params,
      ["referencialName", "nodeToCopy"]
    );
    const response =
      await socioGrpcClient.referencial.referencial_services.ReferencialNodeControllerPromiseClient.duplicateNode(
        request,
        {}
      );
    const nodes = response.toObject().resultsList;
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      // Display the first parent node duplicated
      if (i === 0) {
        node.isDisplay = true;
      }
      // Set numchild to node to have the toggleExpandBtn displayed in UI
      if (nodes[i + 1] && nodes[i + 1].level > node.level) {
        node.numchild = nodes.length - (i + 1);
      }
      // MS - INFO - 13/10/2021 - perf can be improved if we fetch all RapsoSPSNode in one call
      let rapsoSPSNode = await dispatch("retrieveRapsoSPSNode", node.objectId);
      rapsoSPSNode.referencialNode = node.uuid;
      rapsoSPSNode = new RapsoSPSNode(rapsoSPSNode);
      node.spsNode = rapsoSPSNode;
      node.referencialName = requestData.nodeToCopy.referencialName;
    }

    for (const node of nodes) {
      node.manuallyCreated = true;
      node.referencialName = "pgc";
      
    }
    const newInserted = await ReferencialNode.insert({
      data: nodes,
    });
    for (const node of newInserted.referencialNodes) {
      // Update the children/numchild of the parent node to have the toggleExpandBtn displayed in UI
      const parentNode = await socioReferencialUtils.getParentNode(node);
      if (parentNode) {
        await socioReferencialUtils.updateNodeChildren(parentNode);
        await parentNode.$save();
      }
    }
    const nodeSelected = params.nodeSelected;
    if(nodeSelected.children) {
      nodeSelected.children = {
        "rapsoSPSNodes": newInserted.rapsoSPSNodes[0],
        "referencialNodes": newInserted.referencialNodes[0]
      };
    } else {
      nodeSelected.children.rapsoSPSNodes.push(newInserted.rapsoSPSNodes[0]);   
      nodeSelected.children.referencialNodes.push(newInserted.referencialNodes[0]);   
    }
    const parentNode = await socioReferencialUtils.getParentNode(nodeSelected);
    if (parentNode) {
      socioReferencialUtils.updateNodeChildren(nodeSelected);
      await nodeSelected.$save();
    }
  
  },

  async duplicateDiuoNodes(
    { dispatch, rootGetters },
    { sourceNodesUuid, targetNodesUuid }
  ) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.rapsosps_back.referencial.DuplicateNodeRequest,
      {
        sourceNodesUuid,
        targetNodesUuid,
        projectUuid: rootGetters["operation/getOperationUuid"],
      }
    );
    const response =
      await socioGrpcClient.rapsosps_back.referencial.DiuoNodeControllerPromiseClient.duplicateNode(
        request,
        {}
      );

    let results = response.toObject().resultsList;
    if (results.length === 0) return;
    results = results.map((res) => renameKeys(grpcPropertiesToRename, res));
    await Diuo.insert({ data: results });
    await dispatch("fetchDiuoExtraDatas");
  },

  async updateNodesWithMandatoryMissionCodes(
    { rootState },
    { referencialFetched, analysisNodes }
  ) {
    // INFO - MS - 15/02/22: For PGC, this set isSelected to true in ReferencialNode
    // if his RapsoSPSNode has a mandatoryMissionCodes that match the project mission code
    // when in configurator mode
    if (referencialFetched !== "pgc") return;

    const nodeWithmandatoryMissions = analysisNodes.filter(
      (node) =>
        node.spsNode.mandatoryMissionCodes.length > 0 &&
        node.spsNode.mandatoryMissionCodes.some(
          (code) => code === rootState.operation.currentOperation.mission
        )
    );

    await ReferencialNode.update({
      where: (node) =>
        nodeWithmandatoryMissions.find((n) => n.uuid === node.uuid),
      data: { isSelected: true },
    });
  },

  async updateTreeAfterDragNDrop({ getters, rootGetters }, data) {
    let parentTargetNode = await socioReferencialUtils.getParentNode(
      data.target
    );
    let parentSourceNode = await socioReferencialUtils.getParentNode(
      data.source
    );

    const rootNodeUuid = getters.getReferencialVersion(
      data.referencialName
    ).rootNodeUuid;

    if (!parentTargetNode) {
      parentTargetNode = {
        uuid: rootNodeUuid,
        path: "root",
      };
    }

    if (data.isDropAfterLastChild) {
      const pathSplitted = data.target.path.split(".");
      pathSplitted.splice(
        pathSplitted.length - 1,
        1,
        (data.target.code + 1).toString()
      );
      const path = pathSplitted.join(".");
      data.target.code = data.target.code + 1;
      data.target.path = path;
    }

    // Update the source node path and code with the target
    await ReferencialNode.update({
      where: (node) =>
        node.referencialName === data.referencialName &&
        node.uuid === data.source.uuid,
      data: {
        path: data.target.path,
        code: data.target.code,
      },
    });

    // Get source node children
    const childrenSourceNode = flatNodes(
      data.source.children,
      data.referencialName
    );

    // Update source node children path and code related to the target
    childrenSourceNode.forEach((node) => {
      const referencialNode = ReferencialNode.find(node.uuid);
      referencialNode.path = referencialNode.path.replace(
        data.source.path,
        data.target.path
      );
      referencialNode.code += 1;
      referencialNode.$save();
    });

    // Get siblings node to update due to move source => target
    const nodeImpactedToUpdate = await ReferencialNode.query()
      .where((node) => {
        const targetPathToInt = parseInt(data.target.path.split(".").join(""));
        const currentNodePathToInt = parseInt(node.path.split(".").join(""));
        return data.isDropAfterLastChild
          ? false
          : node.uuid === data.target.uuid ||
          (node.referencialName === data.referencialName &&
            node.level === data.source.level &&
            (parentTargetNode.path === "root"
              ? true
              : node.path.startsWith(`${parentTargetNode.path}.`)) &&
            currentNodePathToInt > targetPathToInt);
      })
      .get();

    // Update path and code for each siblings node impacted by the move source => target
    nodeImpactedToUpdate.forEach((node) => {
      const children = flatNodes(node.children, data.referencialName).map(
        (n) => n.uuid
      );
      const childrenToUpdate = ReferencialNode.query()
        .where((n) => children.includes(n.uuid))
        .get();

      childrenToUpdate.forEach((child) => {
        const childPathSplit = child.path.split(".");
        childPathSplit.splice(node.level - 1, 1, (node.code + 1).toString());
        child.path = childPathSplit.join(".");
        child.$save();
      });

      const nodePathSplit = node.path.split(".");
      nodePathSplit.splice(node.level - 1, 1, (node.code + 1).toString());
      node.path = nodePathSplit.join(".");
      node.code += 1;
      node.$save();
    });

    // Increment/decrement children/numchild of source and target parents node if move is for example: 2.2 => 4.5
    if (parentTargetNode.uuid !== rootNodeUuid) {
      socioReferencialUtils.updateNodeChildren(parentTargetNode);
      socioReferencialUtils.updateNodeChildren(parentSourceNode);
    }

    // Make the call to backend at end for preserve UI drag n drop animation
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services
        .ReferencialNodeUpdateMoveRequest,
      {
        uuid: data.source.uuid,
        target: parentTargetNode.uuid,
        code: data.target.code,
      }
    );
    const user = rootGetters["user/getCurrentUser"];
    request.setModifiedByUuid(user.usermanagementUuid);
    await socioGrpcClient.referencial.referencial_services.ReferencialNodeControllerPromiseClient.updateMove(
      request,
      {}
    );
  },

  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤ DELETE ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤

  async removeDiuoExtra({ rootGetters }, uuid) {
    const metadata = {
      filters: JSON.stringify({
        project_uuid: rootGetters["operation/getOperationUuid"],
      }),
    };

    const request =
      new socioGrpcClient.rapsosps_back.referencial.DiuoNodeExtraDestroyRequest();
    request.setUuid(uuid);
    await socioGrpcClient.rapsosps_back.referencial.DiuoNodeExtraControllerPromiseClient.destroy(
      request,
      metadata
    );
    DiuoExtra.delete(uuid);
  },

  async removeReferencialNodeAndRelation({ dispatch, getters }, node) {
    // Check and remove node children before
    if (node.numchild > 0) {
      const childNodes = await ReferencialNode.query()
        .where((n) => {
          return (
            n.referencialName === node.referencialName &&
            n.path.startsWith(`${node.path}.`) &&
            node.level + 1 === n.level
          );
        })
        .with("spsNode")
        .get();
      for (const child of childNodes) {
        await dispatch("removeReferencialNodeAndRelation", child);
      }
    }

    // Call Destroy on µService Referencial for remove the ReferencialNode
    await dispatch("removeReferencialNode", node);

    // If "removeReferencialNode()" fail, the code below is not executed (because of throw new Error("referencialError"))
    // Update the numchild/children of the parent node
    const parentNode = await socioReferencialUtils.getParentNode(node);
    if (parentNode) {
      socioReferencialUtils.updateNodeChildren(parentNode);
    }

    // If a nodeAttached is find, call Destroy on RapsoSPSBack
    // for remove the PgcNode/DiuoNode(Extra) data
    const nodeAttached = getters.getOtherNodeDataAttached(node);
    if (nodeAttached) {
      await dispatch("removeReferencialNodeRelatedData", {
        modelName: node.referencialName,
        nodeAttached,
      });
    }
  },

  async removeReferencialNode(context, node) {
    try {
      const request =
        new socioGrpcClient.referencial.referencial_services.ReferencialNodeDestroyRequest();
      request.setUuid(node.uuid);
      await socioGrpcClient.referencial.referencial_services.ReferencialNodeControllerPromiseClient.destroy(
        request,
        {}
      );
    } catch {
      throw new Error("referencialError");
    }
    // if no error catched, remove data in frontend vuex-orm entities stores
    await ReferencialNode.delete(node.uuid);
    await RapsoSPSNode.delete(node.spsNode?.uuid);
  },

  async removeReferencialNodeRelatedData(context, { modelName, nodeAttached }) {
    try {
      // INFO - B.L - 29/06/2022 - Adds projct uuid in metadata for permission checking in sps back
      const metadata = {
        filters: JSON.stringify({
          project_uuid: nodeAttached.projectUuid,
        }),
      };
      const modelBackendFormat = formatModelName(modelName);
      const request = new socioGrpcClient.rapsosps_back.referencial[
        [`${modelBackendFormat}DestroyRequest`]
      ]();
      request.setUuid(nodeAttached.uuid);
      await socioGrpcClient.rapsosps_back.referencial[
        `${modelBackendFormat}ControllerPromiseClient`
      ].destroy(request, metadata);
    } catch {
      throw new Error("spsBackError");
    }

    // if no error catched, remove data in frontend vuex-orm entities stores
    if (modelName === "pgc") {
      await Pgc.delete((pgcNode) => pgcNode.spsNode === nodeAttached.spsNode);
      return;
    }
    await Diuo.delete(nodeAttached.spsNode);
    await DiuoExtra.delete(
      (diuoExtra) => diuoExtra.diuoNode === nodeAttached.uuid
    );
  },

  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤ ARCHIVE ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤

  async archiveReferencialNode({ rootGetters }, referencialNode) {
    const excludedField = [
      "$id",
      "referencialName",
      "spsNode",
      "isDisplay",
      "isOpen",
      "conditionalTitles",
      "conditionalDisplay",
      "rapsotecNode",
      "children",
      "createdAt",
      "updatedAt",
      "path",
      "level",
      "readablePath",
      "modificationOf",
      "label",
      "description",
      "code",
      "parentNode",
      "objectId",
      "numchild",
      "isSelected",
    ];

    const client =
      socioGrpcClient.referencial.referencial_services
        .ReferencialNodeControllerPromiseClient;
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services
        .ReferencialNodeArchiveReferencialNodeRequest,
      referencialNode,
      excludedField
    );

    request.setModifiedByUuid(
      rootGetters["user/getCurrentUser"].usermanagementUuid
    );
    const response = await client.archiveReferencialNode(request, {});

    const updatedReferencialNode = renameKeys(
      grpcPropertiesToRename,
      response.toObject()
    );

    return await ReferencialNode.update({
      where: updatedReferencialNode.uuid,
      data: {
        isArchived: updatedReferencialNode.isArchived,
      },
    });
  },
};

const mutations = {
  SET_REFERENCIAL_VERSION: (state, data) => {
    if (!data) {
      for (const version in state.referencialVersion) {
        state.referencialVersion[version] = null;
      }
      return;
    }
    state.referencialVersion[data.referencialFetched] = data.versionData;
  },
  SET_CUSTOM_REFERENCIAL_NAME: (state, data) => {
    state.referencialVersion[data.referencialFetched].customReferencialName =
      data.versionData.customReferencialName;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
