import { socioGrpcClient } from "@/setup/socioGrpcClient";

const state = {};

const getters = {};

const actions = {
  async fetchMissions(context, { metadata }) {
    const request =
      new socioGrpcClient.rapsosps_back.projects.MissionListRequest();
    const response =
      await socioGrpcClient.rapsosps_back.projects.MissionControllerPromiseClient.list(
        request,
        metadata
      );
    return response.toObject().resultsList;
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
