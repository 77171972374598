    export const RoleCategoriesEnum = {
        MAITRE_OUVRAGE: "MAITRE_OUVRAGE",
        MAITRE_OEUVRE: "MAITRE_OEUVRE",
        ENTREPRISE: "ENTREPRISE",
        COORDONNATEUR_DE_REFERENCE: "COORDONNATEUR_DE_REFERENCE",
        ORGANISME_DE_PREVENTION: "ORGANISME_DE_PREVENTION",
        ENTREPRISE_GENERALE: "ENTREPRISE_GENERALE",
        CONTRACTANT_GENERAL: "CONTRACTANT_GENERAL",
        MAITRE_OUVRAGE_DELEGUE: "MAITRE_OUVRAGE_DELEGUE",
        AUTRE: "AUTRE",
        INTERVENANT_MOA: "INTERVENANT_MOA",
        AVOISINANT_MOA: "AVOISINANT_MOA",
        AVOISINANT_MOE: "AVOISINANT_MOE",
        AVOISINANT_ENTREPRISE: "AVOISINANT_ENTREPRISE",
        AVOISINANT_CSPS: "AVOISINANT_CSPS",
        AVOISINANT_ORG_PREVENTION: "AVOISINANT_ORG_PREVENTION",
        ASSISTANT_COORDONNATEUR_SPS: "ASSISTANT_COORDONNATEUR_SPS",
        CSPS_CONCEPTION: "CSPS_CONCEPTION",
        CSPS_REALISATION: "CSPS_REALISATION",
        AVOISINANT_AUTRE: "AVOISINANT_AUTRE",
        CONTROLE_TECHNIQUE: "CONTROLE_TECHNIQUE",
        AVOISINANT_OPC: "AVOISINANT_OPC",
        OPC: "OPC",
        ASSISTANT_MAITRE_OUVRAGE: "ASSISTANT_MAITRE_OUVRAGE",
        CONDUCTEUR_OPERATION: "CONDUCTEUR_OPERATION",
        MAITRE_OEUVRE_CONCEPTION: "MAITRE_OEUVRE_CONCEPTION",
        MAITRE_OEUVRE_REALISATION: "MAITRE_OEUVRE_REALISATION",
        INSPECTION_DU_TRAVAIL: "INSPECTION_DU_TRAVAIL",
        CARSAT_OU_CRAMIF: "CARSAT_OU_CRAMIF",
        OPPBTP: "OPPBTP",
        MEDECINE_DU_TRAVAIL: "MEDECINE_DU_TRAVAIL",
        CHEF_ETABLISSEMENT: "CHEF_ETABLISSEMENT",
        MAITRE_OEUVRE_AMIANTE: "MAITRE_OEUVRE_AMIANTE",
        MAITRE_OEUVRE_PLOMB: "MAITRE_OEUVRE_PLOMB",
        ARCHITECTE_MONUMENT_HISTORIQUE: "ARCHITECTE_MONUMENT_HISTORIQUE",
        ARCHITECTE_DECORATEUR: "ARCHITECTE_DECORATEUR",
        ARCHITECTE_ESPACE_VERT: "ARCHITECTE_ESPACE_VERT",
        ARCHITECTE: "ARCHITECTE",
        ORGANISME_ET_DIVERS: "ORGANISME_ET_DIVERS",
        AVOISINANT: "AVOISINANT",
        COORDONNATEUR_SPS: "COORDONNATEUR_SPS",
        SUB_COORDONNATEUR_SPS: "SUB_COORDONNATEUR_SPS",
        ENTREPRISE_UTILISATRICE: "ENTREPRISE_UTILISATRICE",
        ENTREPRISE_EXTERIEURE: "ENTREPRISE_EXTERIEURE",
        ALTERNATE_PREVENTER: "ALTERNATE_PREVENTER", // Front-end only, used for renaming SUB_COORDONNATEUR_SPS during mission kdad
        PREVENTER: "PREVENTER", // Front-end only, used for renaming SUB_COORDONNATEUR_SPS during mission kdad
    };