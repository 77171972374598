import { v4 as uuidv4 } from "uuid";

import { utils } from "@socotec.io/socio-vue-components";
const templateCodes = utils.report.templateCodes;

export const SET_REPORT_BEING_EDITED = "SET_REPORT_BEING_EDITED";
export const SAVE_CURRENT_REPORT = "SAVE_CURRENT_REPORT";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const REMOVE_REPORT = "REMOVE_REPORT";
export const SET_CURRENT_REPORT = "SET_CURRENT_REPORT";
export const SET_SAVED_REPORTS = "SET_SAVED_REPORTS";

const reportDefaultData = {
  [templateCodes.COMMON_INSPECTION_REPORT]: {
    persons: {
      representatives: [],
      contributors: [],
      signatures: [],
    },
    observations: {},
    measures: {},
    installations: {},
  },
  [templateCodes.COORDINATION_NOTE]:{
    persons: {
      signatures: [],
      recipients: [],
    },
    documentNature: {},
    //visitDate: null,
    observationsNotes: {},
    includePPSPS: false,
    annexes: [],
  },
  [templateCodes.OBSERVATION_NOTE]:{
    persons: {
      signatures: [],
      recipients: [],
    },
    documentNature: {},
    observationsNotes: {},
    annexes: [],
  },
}

const getReportDefaultData = (author, templateCode) => ({
  uuid: uuidv4(),
  title: "",
  templateCode: templateCode,
  author: author,
  ...reportDefaultData[templateCode],
  offline: true,
  case: null,
  operation: null,
  createdAt: new Date(),
});

const state = {
  currentUser: null,
  currentReport: null,
  offlineReports: [],
};

const getters = {
  currentReport: (state) => {
    return state.currentReport;
  },
  getCurrentUser: (state) => {
    return state.currentUser;
  },
  getSavedReports: (state) => {
    return state.offlineReports;
  },
  getUnassignedReports: (state) => {
    return state.offlineReports.filter((r) => !r.operation);
  },
  isForKdadOperation: (state) =>{
    return state.currentReport.templateCode === templateCodes.OBSERVATION_NOTE;
  },
  currentReportTemplateCode: (state) => {
    return state.currentReport.templateCode;
  }
};

const actions = {
  createLocalReport({ commit, getters }, templateCode) {
    const author = getters.getCurrentUser;
    const newReport = getReportDefaultData(author, templateCode);
    commit(SET_REPORT_BEING_EDITED, newReport);
  },
  setCurrentUser({ commit }, user) {
    commit(SET_CURRENT_USER, user);
  },
  saveCurrentReport({ commit }) {
    commit(SAVE_CURRENT_REPORT);
  },
  setCurrentReport({ commit }, report) {
    commit(SET_CURRENT_REPORT, report);
  },
  setSavedReports({ commit }, reports) {
    commit(SET_SAVED_REPORTS, reports);
  },
  removeLocalReports({ commit }, ids) {
    for (const id in ids) {
      commit(REMOVE_REPORT, id);
    }
  },
};

const mutations = {
  [SET_REPORT_BEING_EDITED]: (state, report) => {
    state.currentReport = report;
  },
  [SET_CURRENT_USER]: (state, user) => {
    state.currentUser = user;
  },
  [SAVE_CURRENT_REPORT]: (state) => {
    state.offlineReports.push(state.currentReport);
    state.currentReport = null;
  },
  [SET_CURRENT_REPORT]: (state, report) => {
    state.currentReport = report;
  },
  [SET_SAVED_REPORTS]: (state, reports) => {
    state.offlineReports = reports;
  },
  [REMOVE_REPORT]: (state, id) => {
    const i = state.offlineReports.map((r) => r.id).indexOf(id);
    state.offlineReports.splice(i, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
