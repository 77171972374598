const ReportGeneration = () =>
  import(
    /* webpackChunkName: "OperationReports" */ "@/components/rapsosps/operations/operation/operation-reports/ReportGeneration"
  );

const OperationReportList = () =>
  import(
    /* webpackChunkName: "OperationReports" */ "@/components/rapsosps/operations/operation/operation-reports/OperationReportList"
  );

export default [
  {
    path: "generation/:templateId/:standByReportId/:step/",
    props: true,
    name: "report-generation",
    component: ReportGeneration,
  },
  {
    path: "",
    name: "operation-reports-list",
    component: OperationReportList,
  },
];
