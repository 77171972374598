import { DocumentType, DocumentStatus } from "@socotec.io/socio-vue-components";
import { Document } from "@socotec.io/socio-vue-components";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { restDocumentClient } from "@/setup/restDocumentClient";
const google_protobuf_struct_pb = require("google-protobuf/google/protobuf/struct_pb.js");
import { utils } from "@socotec.io/socio-vue-components";
const documentConst = utils.documentsConstants;
import { documentStatuses, documentTypes } from "@/utils/consts/documents";

import {
  snakeToCamel,
  renameKeys,
  camelToSnakeCase,
} from "@/utils/utilsGrcpRest";

const formatDocData = (obj) => {
  return {
    uuid: obj.uuid,
    servicesRelatedName: obj.servicesRelatedNameList,
    docStatus: obj.docStatus,
    docType: obj.docType,
    relatedObjects: obj.relatedObjectsList,
    tags: obj.tagsList,
    name: obj.name,
    source: obj.source,
    ref: obj.ref,
    version: obj.version,
    datetime: obj.datetime,
    oldOriginId: obj.oldOriginId,
    origin: obj.origin,
    originId: obj.originId?.toString(),
    gedProjectId: obj.gedProjectId?.toString(),
    metaDatas: new google_protobuf_struct_pb.Struct.fromJavaScript(
      obj.metaDatas
    ),
  };
};

const deserializeDocumentCustomMessage = (msg) => {
  const documentData = msg.toObject();
  documentData.metaDatas = msg.getMetaDatas().toJavaScript();
  return documentData;
};

const state = {
  documentSelected: [],
  documentTags: [], // TODO - MS - Vuex orm model for tags
  documentsCount: 0,
  documentTablePageSize: 10,
  backendOrdering: true,
};

const getters = {
  getDocuments: (state) => {
    const documentsQuery = Document.query();
    if (state.backendOrdering) {
      return documentsQuery.limit(state.documentTablePageSize).get();
    } else {
      return documentsQuery
        .orderBy("createdAt", "desc")
        .limit(state.documentTablePageSize)
        .get();
    }
  },
  getAllDocuments: () => {
    return Document.query()
      .orderBy("createdAt", "desc")
      .get();
  },
  getDocumentsByType: () => (type) => {
    return Document.query()
      .orderBy("createdAt", "desc")
      .where("docType", type)
      .get();
  },
  getDocumentSelected: (state) => {
    return state.documentSelected;
  },
  getDocumentTags: (state) => {
    return state.documentTags;
  },
  getDocumentCount: (state) => {
    return state.documentsCount;
  },
  getDocumentTablePageSize: (state) => {
    return state.documentTablePageSize;
  },
  getDocumentTypes() {
    return DocumentType.query().orderBy("name").get();
  },
  getDocumentStatus() {
    return DocumentStatus.query().orderBy("name").get();
  },
  getIssuedDocumentStatusUuid() {
    return DocumentStatus.query().where("name", documentStatuses.ISSUED).first()
      .uuid;
  },
  getDocumentTypeByUuid: () => (uuid) => {
    return DocumentType.query().where("uuid", uuid).first();
  },
  getDocumentTypeByName: () => (name) => {
    return DocumentType.query().where("name", name).first();
  },
  getAvailableManualUploadDocumentTypes() {
    const availableDocumentTypes = [
      documentTypes.CONVENTION,
      documentTypes.DOCUMENT_RECEIVED,
      documentTypes.PHOTO,
      documentTypes.PPSPS,
      documentTypes.DOCUMENT_SENT,
    ];

    return DocumentType.query()
      .where(({ name }) => {
        return availableDocumentTypes.includes(name);
      })
      .get();
  },
};

const actions = {
  setSelectedDocument({ commit }, documents) {
    commit("SET_SELECTED_DOCUMENTS", documents);
  },
  async fetchDocumentTags({ commit }, params) {
    const request = new socioGrpcClient.document.document.TagListRequest();
    try {
      const response =
        await socioGrpcClient.document.document.TagControllerPromiseClient.list(
          request,
          params.metadata
        );
      commit("SET_TAGS", response.toObject().resultsList);
    } catch (error) {
      console.log(error);
      throw new Error();
    }
  },

  async fetchDocumentTypes() {
    const request =
      new socioGrpcClient.document.document.DocumentTypeListRequest();
    const response =
      await socioGrpcClient.document.document.DocumentTypeControllerPromiseClient.utils.listAllObjects(
        request,
        {}
      );
    await DocumentType.insert({ data: response });
  },

  async fetchDocumentStatus() {
    const request =
      new socioGrpcClient.document.document.DocumentStatusListRequest();
    const response =
      await socioGrpcClient.document.document.DocumentStatusControllerPromiseClient.utils.listAllObjects(
        request,
        {}
      );
    await DocumentStatus.insert({ data: response });
  },

  /**
   * Fetch document by id
   * @param [_]
   * @param uuid
   * @returns {Promise<Document>}
   */
  async fetchDocument(_, uuid) {
    const request =
      new socioGrpcClient.document.document.DocumentCustomRetrieveRequest();

    request.setUuid(uuid);

    const response =
      await socioGrpcClient.document.document.DocumentCustomControllerPromiseClient.documentCustomExtendRetrieve(
        request,
        {}
      );
    const document = deserializeDocumentCustomMessage(response);

    await Document.insert({ data: document });

    return document;
  },

  /**
   * Fetch document list
   * @context [commit]
   * @param metadata {Object}
   * @param createData {Boolean} gives the possibility to just return the response array or to create it in the store
   * @returns {Promise<void> || Array}
   */
  async fetchDocumentList({ commit }, { metadata, createData = true }) {
    const request =
      new socioGrpcClient.document.document.DocumentCustomListRequest();
    const response =
      await socioGrpcClient.document.document.DocumentCustomControllerPromiseClient.documentCustomExtendList(
        request,
        metadata
      );

    // INFO - Léo Hauchecorne - 09/022023 - Manually patch the metaDatas, because .toObject() does not deserialize it well.
    const result = response
      .getResultsList()
      .map(deserializeDocumentCustomMessage);

    if (!createData) return result;

    commit("UPDATE_DOCUMENT_COUNT", response.getCount());

    await Document.create({ data: result });
  },

  async insertImportDocument({commit, state}, documentUuidList) {
    const metadata = {
      filters: JSON.stringify({
        uuid: documentUuidList.join(',')
      }),
      pagination: JSON.stringify({
        page_size: 100, // hopefully nobody will import more than 100 docs at once
        page: 1,
      }),
    };
    
    const request =
      new socioGrpcClient.document.document.DocumentCustomListRequest();
    const response =
      await socioGrpcClient.document.document.DocumentCustomControllerPromiseClient.documentCustomExtendList(
        request,
        metadata  
    );
    const result = response
    .getResultsList()
    .map(deserializeDocumentCustomMessage);

    commit("UPDATE_DOCUMENT_COUNT", state.documentsCount + response.getCount());
    await Document.insert({ data: result });

  },

  async createDocument(
    { commit, getters, dispatch },
    { operationId, ...datas }
  ) {
    let result = null;
    if (
      datas.form.origin === documentConst.DocumentSource.BIMDATA_GED &&
      datas.file !== null
    ) {
      result = await dispatch("createBimdataDocument", {
        operationId,
        ...datas,
      });
    } else {
      result = await dispatch("createManualDocument", {
        operationId,
        ...datas,
      });
    }
    commit("UPDATE_DOCUMENT_COUNT", getters.getDocumentCount + 1);
    commit("UPDATE_DOCUMENT_ORDER_BY_BACKEND", false);
    return result;
  },

  /**
   * Fetch last document dates pey company
   * @param [_]
   * @param documentType {string}
   * @param objectIds {string[]}
   * @param companiesId {string[]}
   * @returns {Promise<[{recentDate, oldestDate, companyUuid}]>}
   */
  async fetchLastDocumentDatePerContributor(
    _,
    { documentType, objectIds, companiesId }
  ) {
    const request =
      new socioGrpcClient.document.document.DocumentCustomGetDocumentDatesPerCompanyRequest();
    request.setDocType(documentType);
    request.setObjectIdsList(objectIds);
    request.setCompanyUuidList(companiesId);

    const response =
      await socioGrpcClient.document.document.DocumentCustomControllerPromiseClient.getDocumentDatesPerCompany(
        request,
        {}
      );

    return response.toObject().resultsList;
  },

  async createBimdataDocument({ rootGetters }, { operationId, ...datas }) {
    const docFile = new FormData();
    docFile.append("file", datas.file);
    docFile.append("cloudId", process.env.VUE_APP_CLOUD_ID);
    docFile.append(
      "gedProjectId",
      rootGetters["operation/getOperationOriginId"]
    );
    datas.form.relatedObjectsList = [operationId];
    datas.form.servicesRelatedNameList = [
      process.env.VUE_APP_RAPSOSPS_SERVICE_ID,
    ];
    datas.form.metaDatas = JSON.stringify(datas.form.metaDatas);
    Object.entries(datas.form).forEach(([key, value]) => {
      docFile.append(key, value);
    });
    try {
      const bimDataDoc = await restDocumentClient.post(
        "/documents/upload_document/",
        docFile,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      let result = snakeToCamel({
        ...bimDataDoc.data,
        ...bimDataDoc.data.document,
      });
      const keysMap = {
        relatedObjects: "relatedObjectsList",
        servicesRelatedName: "servicesRelatedNameList",
        tags: "tagsList",
      };
      result = renameKeys(keysMap, result);
      return await Document.insert({ data: result });
    } catch (error) {
      console.log(error);
      throw new Error();
    }
  },
  /**
   * Create a manual document
   * @param context
   * @param operationId
   * @param datas
   * @returns {Promise<{documents: Document[]}>}
   */
  async createManualDocument(context, { operationId, ...datas }) {
    // INFO - MS - 22/07/2021 - GRPC call on microservice Document
    datas.form.relatedObjectsList = [operationId];
    datas.form.servicesRelatedNameList = [
      process.env.VUE_APP_RAPSOSPS_SERVICE_ID,
    ];
    const docData = formatDocData(datas.form);
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.document.document.DocumentFlatRequest,
      docData
    );
    try {
      const response =
        await socioGrpcClient.document.document.DocumentCustomControllerPromiseClient.createWithDocument(
          request,
          {}
        );
      const result = deserializeDocumentCustomMessage(response);
      return await Document.insert({ data: result });
    } catch (error) {
      console.log(error);
      throw new Error();
    }
  },
  async updateDocument({ dispatch }, data) {
    if (
      data.form.origin === documentConst.DocumentSource.BIMDATA_GED &&
      data.file !== null &&
      !data.multiple
    ) {
      // INFO - MS - 22/07/2021 - Update a single doc with file on Bimdata ged
      return await dispatch("updateDocumentOnBimdata", data);
    }
    // INFO - MS - 22/07/2021 - Update (without file) a single doc or multiple docs
    const docData = formatDocData(data.form);
    const ignoreFields = [
      "isFromLegacySoftware",
      "oldOriginId",
      "originId",
      "gedProjectId",
    ];
    const partialUpdateFields = Object.keys(docData).filter((fieldName) => {
      return !ignoreFields.includes(fieldName);
    });
    partialUpdateFields.forEach((fieldName, fieldIndex) => {
      partialUpdateFields[fieldIndex] = camelToSnakeCase(
        partialUpdateFields[fieldIndex]
      );
    });
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.document.document
        .DocumentCustomExtendedPartialUpdateRequest,
      docData
    );
    request.setMetaDatas(docData.metaDatas);
    request.setUuid(docData.uuid);
    request.setServicesRelatedNameList(docData.servicesRelatedName);
    request.setDocStatus(docData.docStatus);
    request.setRelatedObjectsList(docData.relatedObjects);
    request.setTagsList(docData.tags);
    request.setName(docData.name);
    request.setSource(docData.source);
    request.setRef(docData.ref);
    request.setVersion(docData.version);
    request.setDatetime(docData.datetime);
    request.setPartialUpdateFieldsList(partialUpdateFields);
    try {
      const response =
        await socioGrpcClient.document.document.DocumentCustomControllerPromiseClient.partialUpdateDocumentCustomExtend(
          request,
          {}
        );
      const result = deserializeDocumentCustomMessage(response);
      await Document.update(result);
    } catch (error) {
      console.log(error);
      throw new Error();
    }
  },
  async updateDocumentOnBimdata({ rootGetters }, data) {
    // techDebt - Léo Hauchecorne - 09/02/2023 - Should use the gRPC api of document, but currently, several things
    // are done differently with the REST one compared to the gRPC one.
    // Moreover, the DocumentUploader from socio-grpc-api js utils expects a document and a documentCustom that are not
    // truely equivalent to Document and DocumentCustom models from the microservice, and it is ambiguous which fields of each are actually used.
    // Passing the same vuex-orm model Documen to both these parameters could do the trick. But to avoid unexpected regression,
    // I chose to stick to the REST api, and hopefully some day, the document micro-service and its gRPC api will be refactored.
    const docFile = new FormData();
    docFile.append("file", data.file);
    docFile.append("cloudId", process.env.VUE_APP_CLOUD_ID);
    data.form.servicesRelatedNameList = [
      process.env.VUE_APP_RAPSOSPS_SERVICE_ID,
    ];
    data.form.relatedObjectsList = [rootGetters["operation/getOperationUuid"]];
    Object.entries(data.form.$toJson()).forEach(([key, value]) => {
      // INFO - Léo Hauchecorne - 09/02/2023 - on the back, metaDatas is expected to be a JSON string.
      // However, the other values are expected to be as is.
      if (key === "metaDatas") {
        docFile.append(key, JSON.stringify(value));
      } else {
        docFile.append(key, value);
      }
    });
    // INFO - MS - 22/07/2021 - ensure that bimdata project id is set
    //when user update a MANUAL document to a BIMDATA_GED document
    docFile.set("gedProjectId", rootGetters["operation/getOperationOriginId"]);
    try {
      const bimDataDoc = await restDocumentClient.put(
        "/documents/upload_document/",
        docFile,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      let result = snakeToCamel({
        ...bimDataDoc.data,
        ...bimDataDoc.data.document,
      });
      const keysMap = {
        relatedObjects: "relatedObjectsList",
        servicesRelatedName: "servicesRelatedNameList",
        tags: "tagsList",
      };
      result = renameKeys(keysMap, result);
      return await Document.update({ data: result });
    } catch (error) {
      console.log(error);
      throw new Error();
    }
  },
  async updateMultipleDocs(context, { data, documentIds }) {
    const request =
      new socioGrpcClient.document.document.DocumentCustomBulkUpdateDocumentsRequest();

    for (const documentId of documentIds) {
      const document =
        new socioGrpcClient.document.document.DocumentFlatRequest();
      document.setUuid(documentId);
      document.setDatetime(data.datetime);
      document.setSource(data.source);
      document.setTagsList(data.tagsList || []);
      document.setDocStatus(data.docStatus);
      request.addDocuments(document);
    }
    const response =
      await socioGrpcClient.document.document.DocumentCustomControllerPromiseClient.bulkUpdateDocuments(
        request,
        {}
      );
    const result = response
      .getResultsList()
      .map(deserializeDocumentCustomMessage);

    await Document.update({ data: result });
  },
  /**
   * Delete a document
   * @param [context]
   * @param documentUuid Document uuid
   * @returns {object} { code, message}
   */
  async removeDocument({ commit, getters }, documentUuid) {
    const request =
      new socioGrpcClient.document.document.DocumentCustomDestroyRequest();

    request.setUuid(documentUuid);

    const response =
      await socioGrpcClient.document.document.DocumentCustomControllerPromiseClient.destroy(
        request,
        {}
      );
    const result = response.toObject();

    if (result.code === "SUCCESS") {
      commit("UPDATE_DOCUMENT_COUNT", getters.getDocumentCount - 1);
      return await Document.delete(documentUuid);
    }

    throw new Error(result.message);
  },
  async getDocumentUrlAndName(context, docCustomUuid) {
    const params = {
      docCustomUuid,
    };
    try {
      const file = await restDocumentClient.get(
        "/documents/get_document_url_and_name/",
        { params }
      );
      return { fileUrl: file.data.file_url, fileName: file.data.file_name, size: file.data.size };
    } catch (error) {
      throw new Error(error);
    }
  },
  async updateDocumentStatus(_, { documentId, status }) {
    let request =
      new socioGrpcClient.document.document.DocumentCustomRetrieveRequest();
    request.setUuid(documentId);
    let response =
      await socioGrpcClient.document.document.DocumentCustomControllerPromiseClient.retrieve(
        request,
        {}
      );
    let result = response.toObject();
    request = new socioGrpcClient.document.document.DocumentCustomRequest();
    request.setUuid(result.uuid);
    request.setDocument(result.document);
    request.setDocType(result.docType);
    request.setDocStatus(status);
    request.setRelatedObjectsList(result.relatedObjectsList);
    request.setTagsList(result.tagsList);
    response =
      await socioGrpcClient.document.document.DocumentCustomControllerPromiseClient.update(
        request,
        {}
      );
    result = response.toObject();
    await Document.update(result);
  },
};

const mutations = {
  SET_SELECTED_DOCUMENTS: (state, documents) => {
    state.documentSelected = documents;
  },
  SET_TAGS: (state, tags) => {
    state.documentTags = tags.map((tag) => tag.name);
  },
  RESET_TAGS: (state) => {
    state.documentTags = [];
  },
  UPDATE_DOCUMENT_COUNT: (state, newTotal) => {
    state.documentsCount = newTotal;
  },
  UPDATE_DOCUMENT_TABLE_PAGE_SIZE: (state, pageSize) => {
    state.documentTablePageSize = pageSize;
  },
  UPDATE_DOCUMENT_ORDER_BY_BACKEND: (state, backendOrdering) => {
    state.backendOrdering = backendOrdering;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
