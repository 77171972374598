import { Agency, Case } from "@socotec.io/socio-vue-components";

export default class RapsoSPSCase extends Case {
  static entity = "rapsoSPSCases";
  static baseEntity = super.entity;

  static fields() {
    return {
      ...super.fields(),
      caseUuid: this.uid(),
      caseName: this.attr(""),
      caseManagerEmail: this.attr(""),
      caseManagerUsermanagementUuid: this.attr(null).nullable(),
      caseDescription: this.attr(""),
      deploymentNote: this.attr(""),
      caseUpdatedAt: this.attr(""),
      caseCreatedAt: this.attr(""),
      agencyId: this.attr(""),
      agency: this.belongsTo(Agency, "agencyId"),
    };
  }

}
