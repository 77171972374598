<template>
  <VDataTable
    :headers="headers"
    :items="localReports"
    class="rounded-2 elevation-1"
  >
    <template #[`item.operation`]="{ item }">
      <SearchInput
        class="my-2"
        :label="$t('selectOperation')"
        :searchFunction="searchOperation"
        :filters="operationFilters()"
        v-model="item.operation"
        item-value="uuid"
        :item-text="formatOperationItemText"
        :hint="$t('hintText')"
        persistent-hint
        @input="$emit('input', localReports)"
      />
    </template>
  </VDataTable>
</template>

<script>
import SearchInput from "./SearchInput.vue";
import { mapActions, mapGetters } from "vuex";
import cloneDeep from "lodash/cloneDeep";

const HEADER_CLASSES = "primary white--text";

export default {
  props: {
    reports: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    SearchInput,
  },
  data(vm) {
    return {
      localReports: [],
      headers: [
        {
          class: HEADER_CLASSES,
          text: vm.$t("report"),
          align: "start",
          sortable: false,
          value: "title",
        },
        {
          class: HEADER_CLASSES,
          text: vm.$t("operation"),
          sortable: false,
          value: "operation",
        },
      ],
    };
  },
  watch: {
    reports: {
      immediate: true,
      handler() {
        this.localReports = cloneDeep(this.reports);
      },
    },
  },
  methods: {
    ...mapActions("operation", ["searchOperation"]),
    ...mapGetters("user", ["getCurrentUser"]),

    operationFilters() {
      if (this.getCurrentUser().isDev()) return {};
      return {
        manager_usermanagement_uuid: this.getCurrentUser().usermanagementUuid,
      };
    },
    formatOperationItemText(operation) {
      return operation.projectName;
    },
  },
  i18n: {
    messages: {
      fr: {
        report: "Livrable",
        case: "Affaire",
        operation: "Opération",
        selectCase: "Sélectionner une affaire",
        selectOperation: "Sélectionner une opération",
        hintText: "Saisir les premières lettres de l'opération",
      },
      en: {
        report: "Report",
        case: "Case",
        operation: "Operation",
        selectCase: "Select a case",
        selectOperation: "Select an operation",
        hintText: "Type the first letters",
      },
    },
  },
};
</script>
