const OperationDashboard = () =>
  import(
    /* webpackChunkName: "OperationDashboard" */ "@/components/rapsosps/operations/operation/OperationDashboard"
  );

const OperationIdentityCard = () =>
  import(
    /* webpackChunkName: "OperationIdentityCard" */ "@/components/rapsosps/operations/operation/OperationIdentityCard"
  );

import OperationIdentityCardChildren from "./childrens/operation-identity-card-children";

const OperationRegister = () =>
  import(
    /* webpackChunkName: "OperationRegister" */ "@/components/rapsosps/operations/operation/OperationRegister"
  );

import OperationRegisterChildren from "./childrens/operation-registry-children";

const OperationBatch = () =>
  import(
    /* webpackChunkName: "OperationBatch" */ "@/components/rapsosps/operations/operation/OperationBatch"
  );

const OperationContributors = () =>
  import(
    /* webpackChunkName: "OperationContributors" */ "@/components/rapsosps/operations/operation/OperationContributors"
  );
const NewOperationContributors = () =>
  import(
    /* webpackChunkName: "NewOperationContributors" */ "@/components/rapsosps/operations/operation/NewOperationContributors"
  );

const OperationConceptionAnalysis = () =>
  import(
    /* webpackChunkName: "OperationConceptionAnalysis" */ "@/components/rapsosps/operations/operation/OperationConceptionAnalysis"
  );

const OperationPGC = () =>
  import(
    /* webpackChunkName: "OperationPGC" */ "@/components/rapsosps/operations/operation/OperationPGC"
  );

const OperationDIUO = () =>
  import(
    /* webpackChunkName: "OperationDIUO" */ "@/components/rapsosps/operations/operation/OperationDIUO"
  );
const OperationReports = () =>
  import(
    /* webpackChunkName: "OperationReports" */ "@/components/rapsosps/operations/operation/OperationReports"
  );
const OperationDocuments = () =>
  import(
    /* webpackChunkName: "OperationDocuments" */ "@/components/rapsosps/operations/operation/OperationDocuments"
  );

import OperationReportsChildren from "./childrens/operation-reports-childrens";

const OperationDocumentsSedi = () =>
  import(
    /* webpackChunkName: "OperationDocumentsSedi" */ "@/components/rapsosps/operations/operation/operation-document/OperationDocumentsSedi"
  );

const OperationDocumentsDefault = () =>
  import(
    /* webpackChunkName: "OperationDocumentsDefault" */ "@/components/rapsosps/operations/operation/operation-document/OperationDocumentsDefault"
  );

import OperationConfigChildren from "./childrens/operation-config-children";

const OperationConfig = () =>
  import(
    /* webpackChunkName: "OperationConfig" */ "@/components/rapsosps/operations/operation/OperationConfig"
  );

export default [
  {
    path: "",
    name: "operation-dashboard",
    component: OperationDashboard,
  },
  {
    path: "identity-card",
    component: OperationIdentityCard,
    children: OperationIdentityCardChildren,
  },
  {
    path: "registry",
    component: OperationRegister,
    children: OperationRegisterChildren,
  },
  {
    path: "batch",
    name: "operation-batch",
    component: OperationBatch,
  },
  {
    path: "contributors",
    name: "operation-contributors",
    component: NewOperationContributors,
  },
  {
    path: "contributors-fresh-ui",
    name: "new-operation-contributors",
    component: OperationContributors,
  },
  {
    path: "conception-analysis",
    name: "operation-conception-analysis",
    component: OperationConceptionAnalysis,
  },
  {
    path: "pgc",
    name: "operation-pgc",
    component: OperationPGC,
  },
  {
    path: "diuo",
    name: "operation-diuo",
    component: OperationDIUO,
  },
  {
    path: "reports",
    component: OperationReports,
    children: OperationReportsChildren,
  },
  {
    path: "documents",
    component: OperationDocuments,
    children: [
      {
        path: "",
        name: "default-documents",
        component: OperationDocumentsDefault,
      },
      {
        path: "sedi",
        name: "sedi-documents",
        component: OperationDocumentsSedi,
      },
    ],
  },
  {
    path: "config",
    component: OperationConfig,
    children: OperationConfigChildren,
  },
];
