const documentStatuses = {
  RECEIVED: "RECEIVED",
  IN_PROGRESS: "IN_PROGRESS",
  EXAMINED: "EXAMINED",
  ISSUED: "ISSUED",
};

const documentTypes = {
  CONVENTION: "CONVENTION",
  DOCUMENT_RECEIVED: "DOCUMENT_RECEIVED",
  PHOTO: "PHOTO",
  PPSPS: "PPSPS",
  DOCUMENT_SENT: "DOCUMENT_SENT",
  ANALYSE_IU: "ANALYSE_IU",
  ANALYSE_OC: "ANALYSE_OC",
  CONVOCATION_JOINT_INSPECTION: "CONVOCATION_JOINT_INSPECTION",
  CISSCT_CONVOCATION_MEETING: "CISSCT_CONVOCATION_MEETING",
  CONVOCATION_COORDINATION_MEETING: "CONVOCATION_COORDINATION_MEETING",
  MAIL: "MAIL",
  JOINT_INSPECTION_REPORT: "JOINT_INSPECTION_REPORT",
  CE_JOINT_INSPECTION_REPORT: "CE_JOINT_INSPECTION_REPORT",
  COORDINATION_MEETING_REPORT: "COORDINATION_MEETING_REPORT",
  PRIOR_STATEMENT: "PRIOR_STATEMENT",
  DOCUMENT_DEMAND: "DOCUMENT_DEMAND",
  DIUO: "DIUO",
  PPSPS_REVIEW_AND_HARMONIZATION: "PPSPS_REVIEW_AND_HARMONIZATION",
  OBSERVATION_NOTE: "OBSERVATION_NOTE",
  OBSERVATION_NOTE_MEETING: "OBSERVATION_NOTE_MEETING",
  OBSERVATION_NOTE_VISIT: "OBSERVATION_NOTE_VISIT",
  PREVENTION_PLAN: "PREVENTION_PLAN",
  CISSCT_MEETING_REPORT: "CISSCT_MEETING_REPORT",
  DEPOSIT_REPORT: "DEPOSIT_REPORT",
  PGC: "PGC",
  INITIAL_CONCEPTION_ANALYSIS: "INITIAL_CONCEPTION_ANALYSIS",
  DELIVERY_ORGANIZATION_DOCUMENT: "DELIVERY_ORGANIZATION_DOCUMENT",
  GUIDELINES_PASSATION_PV: "GUIDELINES_PASSATION_PV",
  JOURNAL_REGISTER: "JOURNAL_REGISTER",
  DRAFT_REGULATION: "DRAFT_REGULATION",
};

const documentStatusColors = {
  [documentStatuses.RECEIVED]: "#E6EEF5",
  [documentStatuses.IN_PROGRESS]: "#FFF0EB",
  [documentStatuses.EXAMINED]: "#E6F7FD",
  [documentStatuses.ISSUED]: "#F6F9E6",
};

const documentStatusBorderColors = {
  [documentStatuses.RECEIVED]: "#005499",
  [documentStatuses.IN_PROGRESS]: "#FF6533",
  [documentStatuses.EXAMINED]: "#00ACE8",
  [documentStatuses.ISSUED]: "#A9C409",
};

export {
  documentTypes,
  documentStatuses,
  documentStatusColors,
  documentStatusBorderColors,
};
