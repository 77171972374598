import { Role } from "@socotec.io/socio-vue-components";
import { RoleCategoriesEnum } from "../utils/consts/RoleCategoriesEnum";
import store from "../store";


export default class ExtendedRole extends Role {
    static entity = "extendedRole";
    static baseEntity = super.entity;

    static fields() {
        return {
            ...super.fields(),
        };
    }
    
    static mutators () {
        return {
          name (value) {
            const isCurrentOperationKdad =  store.getters['operation/isCurrentOperationKdad'];
            if(isCurrentOperationKdad) {
                if (value.toLowerCase() === RoleCategoriesEnum.SUB_COORDONNATEUR_SPS.toLowerCase()) {
                    return RoleCategoriesEnum.ALTERNATE_PREVENTER;
                } else if (value.toLowerCase() === RoleCategoriesEnum.COORDONNATEUR_DE_REFERENCE.toLowerCase()) {
                    return RoleCategoriesEnum.PREVENTER;
                }
            }
            return value;
          }
        }  
    }
}
