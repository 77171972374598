import { onMounted, onUnmounted, computed } from "vue";
import { getCurrentInstance } from "vue";

// Name of the routes that are excluded from the offline mode
// On the following routes no modal will be displayed to force the user to perform actions
const EXCLUDED_ROUTES_OFFLINE_MODE = ["offline-report-generation"];

export default function () {
  // TODO - Léo Hauchecorne - 21/03/2023 - change when vue3
  const vm = getCurrentInstance();
  const root = vm.proxy.$root;
  // Computed
  const savedReports = computed(
    () => root.$store.getters["offlineReports/getSavedReports"]
  );
  const isOnline = computed(() => root.$store.state.network.isOnline);
  const isShowOnlineModal = computed(
    () => root.$store.state.network.isShowOnlineModal
  );
  const showBackOnlineDialog = computed(() => {
    return (
      savedReports.value.length > 0 &&
      isOnline.value &&
      !EXCLUDED_ROUTES_OFFLINE_MODE.includes(root.$route.name) &&
      navigator.onLine
    );
  });
  const showOfflineDialog = computed(() => {
    return (
      !isOnline.value &&
      !EXCLUDED_ROUTES_OFFLINE_MODE.includes(root.$route.name) &&
      isShowOnlineModal.value
    );
  });

  // Functions
  const setNetworkState = (isOnline) => {
    root.$store.dispatch("network/setNetworkState", isOnline);
  };
  const triggerSetNeworkState = (e) => {
    const { type } = e;
    setNetworkState(type === "online");
  };
  const setupListeners = () => {
    window.addEventListener("online", triggerSetNeworkState);
    window.addEventListener("offline", triggerSetNeworkState);
  };
  const removeListeners = () => {
    window.removeEventListener("online", triggerSetNeworkState);
    window.removeEventListener("offline", triggerSetNeworkState);
  };

  // Lifecycle hooks
  onMounted(setupListeners);
  onUnmounted(removeListeners);

  return { showBackOnlineDialog, showOfflineDialog, savedReports };
}
