const CaseDocumentsDefault = () =>
  import(
    /* webpackChunkName: "CaseDocumentsDefault" */ "@/components/rapsosps/cases/case/case-documents/CaseDocumentsDefault"
  );

export default [
  {
    path: "",
    name: "CaseDocumentsDefault",
    component: CaseDocumentsDefault,
  },
];
