import { socioGrpcClient } from "@/setup/socioGrpcClient";
import Operation from "@/models/Operation.js";
import RapsoSPSCase from "@/models/RapsoSPSCase.js";
import { Case, Client } from "@socotec.io/socio-vue-components";

const state = {
  currentCase: null,
};

const getters = {
  getCurrentCase: (state) => {
    return state.currentCase;
  },
  getCurrentCaseProjects: (state) => {
    return Operation.query().where("caseNumber", state.currentCase.$id).all();
  },
};

const actions = {
  /**
   * Fetch case list
   * @param [_]
   * @param metadata
   * @returns {Promise<Case[]>}
   */
  async fetchCases(context, metadata) {
    const request =
      new socioGrpcClient.production_unit_lister.lister.FullProjectListRequest();
    const response =
      await socioGrpcClient.production_unit_lister.lister.FullProjectControllerPromiseClient.list(
        request,
        metadata
      );
    const responseObj = response.toObject();
    const results = responseObj.resultsList;
    results.forEach((result) => {
      Client.createFromListerData(result);
      Case.createFromListerData(result);
      Operation.createFromListerData(result);
    });
    return responseObj.count;
  },

  /**
   * Retrieve case by case number
   * @param commit
   * @param caseNumber
   * @returns {Promise<RapsoSPSCase>}
   */
  async retrieveCase({ commit }, caseNumber) {

    const requestBack =
      new socioGrpcClient.rapsosps_back.projects.RetrieveCaseByCaseNumberMessage();

    requestBack.setCaseNumber(caseNumber);

    const responseSpsBack =
      await socioGrpcClient.rapsosps_back.projects.CaseControllerPromiseClient.retrieveCaseByCaseNumber(
        requestBack,
        {}
      );

    const resultSpsCase = responseSpsBack.toObject();
    const result = await RapsoSPSCase.insertOrUpdate({data: resultSpsCase});

    commit("SET_CURRENT_CASE", result.rapsoSPSCases[0]);

    return result.rapsoSPSCases[0];
  },

  /**
   * Update case (Rapsosps back field ONLY)
   * @param _
   * @param caseData {RapsoSPSCase}
   * @returns {Promise<void>}
   */
  async updateCase(_, caseData) {
    const request =
      new socioGrpcClient.rapsosps_back.projects.CaseInputRequest();

    request.setUuid(caseData.caseUuid);
    request.setCaseDescription(caseData.caseDescription);
    request.setDeploymentNote(caseData.deploymentNote);

    await socioGrpcClient.rapsosps_back.projects.CaseControllerPromiseClient.update(
      request,
      {}
    );

    await RapsoSPSCase.insertOrUpdate({
      data: caseData,
    });
  },
};

const mutations = {
  SET_CURRENT_CASE: (state, newCase) => {
    state.currentCase = newCase;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
