<template>
  <VRow justify="center">
    <VDialog :value="showDialog" persistent max-width="900">
      <VCard>
        <VCardTitle class="text-h5">
          {{ $t("dialogTitle") }}
        </VCardTitle>
        <VCardText class="dialog-content">
          <p>{{ $t("dialogContent") }}</p>
          <OfflineReportsTable
            class="my-3"
            :reports="reports"
            @input="setSavedReports"
          />
        </VCardText>
        <VCardActions class="justify-center">
          <VBtn
            color="primary text-none darken-1"
            :disabled="getUnassignedReports.length > 0"
            @click="save"
            depressed
          >
            {{ $t("save") }}
          </VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
  </VRow>
</template>

<script>
import OfflineReportsTable from "./OfflineReportsTable.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    OfflineReportsTable,
  },
  data() {
    return {
      showDialog: true,
    };
  },
  props: {
    reports: {
      type: Array,
      default: () => [],
    },
  },
  async created() {
    try {
      await this.fetchReportTemplateList({});
    } catch (e) {
      this.showErrorNotification(this.$t("errorFetchingReports"));
    }
  },
  computed: {
    ...mapGetters("report", ["getTemplateByCode"]),
    ...mapGetters("offlineReports", ["getUnassignedReports"]),
  },
  methods: {
    ...mapActions("notifications", [
      "showErrorNotification",
      "showSuccessNotification",
    ]),
    ...mapActions("standByReport", ["createStandByReport"]),
    ...mapActions("offlineReports", ["removeLocalReports", "setSavedReports"]),
    ...mapActions("report", ["fetchReportTemplateList"]),

    async save() {
      const createPromises = [];
      for (const report of this.reports) {
        const template = this.getTemplateByCode(report.templateCode);
        createPromises.push(
          this.createStandByReport({
            reportTemplate: template,
            project: report.operation,
            reportGenerationData: report,
          })
        );
      }
      try {
        await Promise.all(createPromises);
        this.removeLocalReports(this.reports.map((r) => r.uuid));
        this.showSuccessNotification(this.$t("saveSuccess"));
        this.showDialog = false;
      } catch (e) {
        this.showErrorNotification(this.$t("errorSave"));
        throw e;
      }
    },
  },
  i18n: {
    messages: {
      fr: {
        dialogTitle: "Une action nécessite votre attention",
        dialogContent: `Vous avez un ou plusieurs livrables de compte rendu d'inspection commune rédigés en mode hors ligne.
        Il est obligatoire de les relier aux bonnes affaires et opérations avant de pouvoir poursuivre la navigation.
        Cette action est irréversible.`,
        save: "Valider et continuer",
        saveSuccess:
          "Vos rapports réalisés en mode hors ligne ont été sauvegardés",
        errorSave: "Impossible de sauvegarder les rapports",
        errorFetchingReports: "Impossible de récupérer les rapports",
      },
      en: {
        dialogTitle: "An action requires your attention",
        dialogContent: `You have one or more joint inspection report that you have written in offline mode.
        It is mandatory to link them to the right cases and operations before you can continue browsing.
        This action is irreversible.`,
        save: "Validate and proceed",
        saveSuccess: "Your offline reports have been saved",
        errorSave: "Cannot save reports",
        errorFetchingReports: "Cannot retrieve reports",
      },
    },
  },
};
</script>

<style scoped>
.dialog-content {
  white-space: pre-line;
}
</style>
