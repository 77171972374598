const ChooseReferencial = () =>
  import(
    /* webpackChunkName: "ChooseReferencial" */ "@/components/rapsosps/referencials/ChooseReferencial"
  );
const OCReferencial = () =>
  import(
    /* webpackChunkName: "OCReferencial" */ "@/components/rapsosps/referencials/OCReferencial"
  );
const IUReferencial = () =>
  import(
    /* webpackChunkName: "IUReferencial" */ "@/components/rapsosps/referencials/IUReferencial"
  );
const ConceptionAnalysisReferencial = () =>
  import(
    /* webpackChunkName: "DesignAnalysisReferencial" */ "@/components/rapsosps/referencials/ConceptionAnalysisReferencial"
  );

export default [
  {
    path: "",
    name: "referencials",
    component: ChooseReferencial,
  },
  {
    path: "ocreferencial",
    name: "oc-referencial",
    component: OCReferencial,
  },
  {
    path: "iureferencial",
    name: "iu-referencial",
    component: IUReferencial,
  },
  {
    path: "concpetionanalysisreferencial",
    name: "conception-analysis-referencial",
    component: ConceptionAnalysisReferencial,
  },
];
