export default {
  en: {
    MAITRE_OUVRAGE: "Maître d'ouvrage",
    MAITRE_OEUVRE: "Maître d'oeuvre",
    ENTREPRISE: "Entreprise",
    COORDONNATEUR_DE_REFERENCE: "Coordonnateur de référence",
    ORGANISME_DE_PREVENTION: "Organisme de prévention",
    ENTREPRISE_GENERALE: "Entreprise générale",
    CONTRACTANT_GENERAL: "Entreprise contractante",
    MAITRE_OUVRAGE_DELEGUE: "Maître d'ouvrage délégué",
    AUTRE: "Autre",
    INTERVENANT_MOA: "Intervenant MOA",
    AVOISINANT_MOA: "Avoisinant MOA",
    AVOISINANT_MOE: "Avoisinant MOE",
    AVOISINANT_ENTREPRISE: "Avoisinant entreprise",
    AVOISINANT_CSPS: "Avoisinant CSPS",
    AVOISINANT_ORG_PREVENTION: "Avoisinant org. de prévention",
    ASSISTANT_COORDONNATEUR_SPS: "Assistant coordonnateur SPS",
    CSPS_CONCEPTION: "CSPS conception",
    CSPS_REALISATION: "CSPS réalisation",
    AVOISINANT_AUTRE: "Avoisinant autre",
    CONTROLE_TECHNIQUE: "Contrôle technique",
    AVOISINANT_OPC: "Avoisinant OPC",
    OPC: "OPC",
    ASSISTANT_MAITRE_OUVRAGE: "Assistant maître d'ouvrage",
    CONDUCTEUR_OPERATION: "Conducteur des opérations",
    MAITRE_OEUVRE_CONCEPTION: "Maître d'oeuvre conception",
    MAITRE_OEUVRE_REALISATION: "Maître d'oeuvre réalisation",
    INSPECTION_DU_TRAVAIL: "Inspection du travail",
    CARSAT_OU_CRAMIF: "CARSAT ou CRAMIF",
    OPPBTP: "OPPBTP",
    MEDECINE_DU_TRAVAIL: "Médecine du travail",
    CHEF_ETABLISSEMENT: "Chef d'établissement",
    MAITRE_OEUVRE_AMIANTE: "Maître d'oeuvre amiante",
    MAITRE_OEUVRE_PLOMB: "Maître d'oeuvre plomb",
    ARCHITECTE_MONUMENT_HISTORIQUE: "Architecte monument historique",
    ARCHITECTE_DECORATEUR: "Architecte décorateur",
    ARCHITECTE_ESPACE_VERT: "Architecte espace verts",
    ARCHITECTE: "Architecte",
    ORGANISME_ET_DIVERS: "Organisme et divers",
    AVOISINANT: "Avoisinant",
    COORDONNATEUR_SPS: "Coordonnateur SPS",
    SUPERVISOR: "Supervisor",
    PROJECT_MANAGER: "Project manager",
    SPECIALIZED_ENGINEER: "Specialized engineer",
    TECHNICIAN: "Technician", 
    SUBCONTRACTING_ANALYZES: "Subcontracting analyzes",
    SUBCONTRACTING_DRILLING: "Drilling technician",
    CUSTOMER: "Customer",
    SUB_COORDONNATEUR_SPS: "Coordonnateur suppléant",
    EDITOR: "Editor",
    ENTREPRISE_UTILISATRICE: "User company",
    ENTREPRISE_EXTERIEURE: "External company",
    EXTERNAL_CONTRIBUTOR: "External contributor",
    ALTERNATE_PREVENTER: "Préventeur suppléant",
    PREVENTER: "Préventeur",
  },
  fr: {
    MAITRE_OUVRAGE: "Maître d'ouvrage",
    MAITRE_OEUVRE: "Maître d'oeuvre",
    ENTREPRISE: "Entreprise",
    COORDONNATEUR_DE_REFERENCE: "Coordonnateur de référence",
    ORGANISME_DE_PREVENTION: "Organisme de prévention",
    ENTREPRISE_GENERALE: "Entreprise générale",
    CONTRACTANT_GENERAL: "Entreprise contractante",
    MAITRE_OUVRAGE_DELEGUE: "Maître d'ouvrage délégué",
    AUTRE: "Autre",
    INTERVENANT_MOA: "Intervenant MOA",
    AVOISINANT_MOA: "Avoisinant MOA",
    AVOISINANT_MOE: "Avoisinant MOE",
    AVOISINANT_ENTREPRISE: "Avoisinant entreprise",
    AVOISINANT_CSPS: "Avoisinant CSPS",
    AVOISINANT_ORG_PREVENTION: "Avoisinant org. de prévention",
    ASSISTANT_COORDONNATEUR_SPS: "Assistant coordonnateur SPS",
    CSPS_CONCEPTION: "CSPS conception",
    CSPS_REALISATION: "CSPS réalisation",
    AVOISINANT_AUTRE: "Avoisinant autre",
    CONTROLE_TECHNIQUE: "Contrôle technique",
    AVOISINANT_OPC: "Avoisinant OPC",
    OPC: "OPC",
    ASSISTANT_MAITRE_OUVRAGE: "Assistant maître d'ouvrage",
    CONDUCTEUR_OPERATION: "Conducteur des opérations",
    MAITRE_OEUVRE_CONCEPTION: "Maître d'oeuvre conception",
    MAITRE_OEUVRE_REALISATION: "Maître d'oeuvre réalisation",
    INSPECTION_DU_TRAVAIL: "Inspection du travail",
    CARSAT_OU_CRAMIF: "CARSAT ou CRAMIF",
    OPPBTP: "OPPBTP",
    MEDECINE_DU_TRAVAIL: "Médecine du travail",
    CHEF_ETABLISSEMENT: "Chef d'établissement",
    MAITRE_OEUVRE_AMIANTE: "Maître d'oeuvre amiante",
    MAITRE_OEUVRE_PLOMB: "Maître d'oeuvre plomb",
    ARCHITECTE_MONUMENT_HISTORIQUE: "Architecte monument historique",
    ARCHITECTE_DECORATEUR: "Architecte décorateur",
    ARCHITECTE_ESPACE_VERT: "Architecte espace verts",
    ARCHITECTE: "Architecte",
    ORGANISME_ET_DIVERS: "Organisme et divers",
    AVOISINANT: "Avoisinant",
    COORDONNATEUR_SPS: "Coordonnateur SPS",
    SUPERVISOR: "Superviseur",
    PROJECT_MANAGER: "Chef de projet",
    SPECIALIZED_ENGINEER: "Ingénieur spécialisé",
    TECHNICIAN: "Technicien",
    SUBCONTRACTING_ANALYZES: "Sous-traitant analyses",
    SUBCONTRACTING_DRILLING: "Technicien forage",
    CUSTOMER: "Client",
    SUB_COORDONNATEUR_SPS: "Coordonnateur suppléant",
    EDITOR: "Rédacteur",
    ENTREPRISE_UTILISATRICE: "Entreprise utilisatrice",
    ENTREPRISE_EXTERIEURE: "Entreprise exterieure",
    EXTERNAL_CONTRIBUTOR: "Utilisateur externe",
    ALTERNATE_PREVENTER: "Préventeur suppléant",
    PREVENTER: "Préventeur",
  },
};
