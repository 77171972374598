import Vue from "vue";
import VueRouter from "vue-router";
import RapsoSPS from "@/components/RapsoSPS.vue";

import store from "@/store";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";
import isLoggedOrOffline from "@/router/guards/isLoggedOrOffline";
import isOffline from "@/router/guards/isOffline";

const OidcCallback = () =>
  import(/* webpackChunkName: "OidcCallback" */ "@/components/OidcCallback");

const UnauthorizedAccessOfflineMode = () =>
  import(
    /* webpackChunkName: "UnauthorizedAccessOfflineMode" */ "@/components/UnauthorizedAccessOfflineMode"
  );

import RapsospsChildrens from "./childrens/rapsosps-children";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: RapsoSPS,
    children: RapsospsChildrens,
    beforeEnter: isLoggedOrOffline,
  },
  {
    path: "/oidc-callback", // Needs to match redirect_uri in you oidcSettings
    name: "oidcCallback",
    component: OidcCallback,
    meta: {
      isVuexOidcCallback: true,
      isPublic: true,
    },
  },
  {
    path: "/unauthorized-access-offline-mode",
    name: "unauthorized-access-offline-mode",
    component: UnauthorizedAccessOfflineMode,
    beforeEnter: isOffline,
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isOnline = window.navigator.onLine;
  if (isOnline) {
    const middleware = await vuexOidcCreateRouterMiddleware(store, "oidc");
    return middleware(to, from, next);
  }
  next();
});

router.afterEach(async (to) => {
  if (to.matched.some(record => record.meta.requiresCurrentUserManagerOrSubstitute)) {
    if (!store.getters["user/isCurrentUserCanUpdate"] && !store.getters["user/isCurrentUserTheProjectSubstitute"]) {
      router.replace({ name: 'operationsList' })
    }
  }
});

export default router;
