const CasesList = () =>
  import(
    /* webpackChunkName: "CasesList" */ "@/components/rapsosps/cases/CasesList"
  );
const Case = () =>
  import(/* webpackChunkName: "Case" */ "@/components/rapsosps/cases/Case");

import CaseChildrens from "./case/case-children";

export default [
  {
    path: "",
    name: "casesList",
    component: CasesList,
  },
  {
    path: ":caseNumber",
    component: Case,
    children: CaseChildrens,
  },
];
